import React, { useState, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import { AtomInput } from "../utils/ui/input/AtomInput";
import { useAppSelector } from "../app/hooks";
import BootstrapTable from "react-bootstrap-table-next";
import MenuItem from "@mui/material/MenuItem";
import filterFactory, {
  textFilter,
} from "react-bootstrap-table2-filter";
import {
  CreateUser,
  DeleteUser,
  LoadUser,
  UpdateUser,
} from "../features/user/usuariosApi";
import { selectUser } from "../features/user/userSlice";
import { toast } from "react-toastify";
import ToastMessage from "./Toasts";
import { validateCPF } from 'validations-br';
import { AtomSelect } from "../utils/ui/input/AtomSelect";

const Usuarios = () => {
  const user = useAppSelector(selectUser);
  const SCHEMA_ADM_INITIAL_VALUES = {
    name: "",
    status: "",
    email: "",
    cpf: "",
    phone: "",
  };
  const [users, setUsers] = useState([]);
  const loadUsuarios = async () => {
    try {
      const resp = await LoadUser();
      console.log("resp.data", resp.data)
      const adms = resp.data.map((adm: any) => {
        return { ...adm, status: adm.isActive };
      });
      setUsers(adms);
    } catch {
      throw new Error("error");
    }
  };

  const excluiUser = async () => {
    try {
      await DeleteUser(isEditting, user.tokenAuth);
      await loadUsuarios();
      setAdding(false);
      setEditting("");
      setadmform(SCHEMA_ADM_INITIAL_VALUES);
      setShow(false);
      toast.success(
        <ToastMessage
          title="Sucesso"
          msg={"O usuário foi excluído com sucesso."}
        />,
        {
          position: "bottom-center",
          autoClose: 500000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
    } catch {
      throw new Error("error");
    }
  };
  const novoUser = async (values: any) => {
    try {
      const resp = await CreateUser({ ...values });
      if (resp.error) {
        let errorStr = "";
        if (resp.error[0].message.includes("Email")) {
          errorStr = "E-mail já cadastrado.";
        }
        if (resp.error[0].message.includes("name")) {
          errorStr = "Nome já cadastrado.";
        }
        if (resp.error[0].message.includes("cpf")) {
          errorStr = "CPF já cadastrado.";
        }
        if (resp.error[0].message.includes("phone")) {
          errorStr = "Celular já cadastrado.";
        }
        toast.error(<ToastMessage title="Erro" msg={errorStr} />, {
          position: "bottom-center",
          autoClose: 500000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        throw new Error("error");
      } else {
        await loadUsuarios();
        setAdding(false);
        setEditting("");
        setadmform(SCHEMA_ADM_INITIAL_VALUES);
        toast.success(
          <ToastMessage
            title="Sucesso"
            msg={"O usuário foi cadastrado com sucesso."}
          />,
          {
            position: "bottom-center",
            autoClose: 500000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      }
    } catch {
      console.log("");
    }
  };
  const updateUser = async (values: any) => {
    try {
      console.log(isEditting);
      const resp = await UpdateUser({ ...values }, isEditting, user.tokenAuth);
      if (resp.error) {
        let errorStr = "";
        if (resp.error[0].message.includes("Email")) {
          errorStr = "E-mail já cadastrado.";
        }
        if (resp.error[0].message.includes("name")) {
          errorStr = "Nome já cadastrado.";
        }
        if (resp.error[0].message.includes("cpf")) {
          errorStr = "CPF já cadastrado.";
        }
        if (resp.error[0].message.includes("phone")) {
          errorStr = "Celular já cadastrado.";
        }
        toast.error(<ToastMessage title="Erro" msg={errorStr} />, {
          position: "bottom-center",
          autoClose: 500000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        throw new Error("error");
      } else {
        await loadUsuarios();
        setAdding(false);
        setEditting("");
        setadmform(SCHEMA_ADM_INITIAL_VALUES);
        toast.success(
          <ToastMessage
            title="Sucesso"
            msg={"O usuário foi editado com sucesso."}
          />,
          {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      }
    } catch {
      console.log("");
    }
  };

  useEffect(() => {
    loadUsuarios();
  }, []);

  function priceFormatter(
    column: any,
    colIndex: any,
    { sortElement, filterElement }: any
  ): any {
    return (
      <div
        style={{
          color: "#3C3C3B",
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
          fontSize: "16px",
          fontWeight: "bold",
          marginLeft: 20,
          width: "100%",
        }}
      >
        {sortElement}
        {column.text}
        {filterElement}
      </div>
    );
  }
  /* CAMPOS DE PESQUISA */
  const columns = [
    {
      dataField: "name",
      headerStyle: {
        backgroundColor: "#989898",
        border: "none",
        borderRadius: "5px 0 0 0",
      },
      text: "Nome",
      filter: textFilter({
        placeholder: "Pesquisar",
        style: { height: 30, marginTop: 5, marginBottom: 5 },
      }),
      headerFormatter: priceFormatter,
      style: {
        width: "100%",
        cursor: "pointer",
        border: "none",
        "text-align": "start",
        paddingLeft: 30,
      },
    },
    {
      dataField: "email",
      text: "E-mail",
      headerStyle: {
        backgroundColor: "#989898",
        borderColor: "#989898",
        borderRadius: "0 5px 0 0",
      },
      filter: textFilter({
        placeholder: "Pesquisar",
        style: { height: 30, marginTop: 5, marginBottom: 5 },
      }),
      headerFormatter: priceFormatter,
      style: {
        cursor: "pointer",
        border: "none",
        "text-align": "start",
        paddingLeft: 30,
      },
    },
  ];

  const SCHEMA_ADM = Yup.object().shape({
    email: Yup.string().email("E-mail inválido").required("Campo obrigatório"),
    status: Yup.boolean().required("Campo obrigatório"),
    cpf: Yup.string()
      .required("Campo obrigatório")
      .test('is-cnpj', 'CPF inválido', value => validateCPF(`${value}`))
      .max(14, 'Campo com maximo de 11 números'),
    phone: Yup.string().required("Campo obrigatório"),
    name: Yup.string().required("Campo obrigatório"),
  });
  const [admform, setadmform] = useState(SCHEMA_ADM_INITIAL_VALUES);
  const [isAdding, setAdding] = useState(false);
  const [isEditting, setEditting] = useState("");
  const rowEvents = {
    onClick: (e: any, row: any, rowIndex: any) => {
      console.log(row);
      setEditting(row.id);
      setadmform({ name: row.name, status: row.isActive, email: row.email, cpf: row.cpf, phone: row.phone, });
    },
  };
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  return (
    <>
      <Modal centered show={show} onHide={handleClose}>
        <Modal.Body>
          <div className="row w-100 d-flex  flex-column">
            Deseja excluir este usuário?
            <div className="d-flex  my-3 flex-row ">
              <Button
                style={{
                  width: "100%",
                  marginLeft: "20px",
                  marginRight: "10px",
                  backgroundColor: "#2CA44E",
                  borderColor: "transparent",
                }}
                variant="secondary"
                onClick={() => excluiUser()}
              >
                Sim
              </Button>

              <Button
                style={{
                  borderColor: "transparent",
                  width: "100%",
                  backgroundColor: "#D32F2F",
                }}
                variant="primary"
                onClick={handleClose}
              >
                Não
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {isAdding || isEditting ? (
        <>
          <div className="w-100 row" style={{ padding: "0px 42px" }}>
            <div className="col text-start d-flex align-items-center">
              <h4
                style={{ fontSize: 18, color: "#3C3C3B", fontWeight: "bold" }}
                className="text-uppercase"
              >
                {`Usuários  >  ${isAdding ? "Novo" : "Editar"
                  } Usuário`}
              </h4>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",

              width: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: 30,
                backgroundColor: "#bcbcbc",
                height: "32px",
              }}
            >
              <span
                style={{
                  padding: "0 10px 0 40px",
                  fontWeight: "bold",
                  fontSize: 16,
                  color: "#3C3C3B",
                }}
              >
                Dados gerais
              </span>
            </div>
          </div>
          <br />
          <div style={{ padding: "0px 42px" }} className="w-100 row">
            <Formik
              initialValues={admform}
              validationSchema={SCHEMA_ADM}
              onSubmit={async (values, { setSubmitting }) => {
                try {
                  const newvalues = {
                    ...values,
                    status: values.status.toString() === 'true' ? true : false,
                    isActive: values.status.toString() === 'true' ? true : false,
                  };
                  console.log(newvalues);
                  if (isAdding) {
                    novoUser(newvalues);
                  } else {
                    updateUser(newvalues);
                  }
                } catch (e) {
                  setSubmitting(false);
                }
              }}
            >
              {({
                errors,
                handleChange,
                submitForm,
                touched,
                isSubmitting,
                handleBlur,
                values,
              }) => (
                <div className="row">
                  <div className="col d-flex flex-column text-start">
                    <AtomInput
                      icon={true}
                      label="Nome *"
                      placeholder="Nome *"
                      width="100%"
                      onChange={handleChange("name")}
                      value={values.name}
                      errors={errors.name}
                      touched={touched.name}
                      onBlur={handleBlur("name")}
                    />

                    <AtomInput
                      icon={true}
                      label="CPF *"
                      placeholder="CPF *"
                      width="100%"
                      onChange={handleChange("cpf")}
                      value={values.cpf}
                      errors={errors.cpf}
                      touched={touched.cpf}
                      onBlur={handleBlur("cpf")}
                      mask="999.999.999-99"
                    />

                    <AtomSelect
                      label="Status *"
                      placeholder="Status *"
                      width="100%"
                      onChange={handleChange("status")}
                      value={values.status}
                      errors={errors.status}
                      touched={touched.status}
                      onBlur={handleBlur("status")}
                    >
                      <MenuItem key={1} value={"true"}>
                        Ativo
                      </MenuItem>
                      <MenuItem key={2} value={"false"}>
                        Inativo
                      </MenuItem>
                    </AtomSelect>

                    {/* <AtomInput
                      icon={true}
                      label="Status *"
                      placeholder="Status *"
                      width="100%"
                      onChange={handleChange("status")}
                      value={values.status}
                      errors={errors.status}
                      touched={touched.status}
                      onBlur={handleBlur("status")}
                    /> */}
                    <div className="d-flex">
                      <Button
                        disabled={isSubmitting}
                        onClick={() => {
                          submitForm();
                        }}
                        style={{
                          borderRadius: 5,
                          marginTop: 30,
                          backgroundColor: "#3C3C3B",
                          color: "white",
                          padding: "12px 0px",
                          height: "35px",
                          width: "150px",
                        }}
                      >
                        <h5
                          style={{
                            margin: 0,
                            fontWeight: "bold",
                            fontSize: "16px",
                            lineHeight: 0
                          }}
                        >
                          Salvar
                        </h5>
                      </Button>
                      {isEditting && (
                        <Button
                          disabled={isSubmitting}
                          onClick={() => {
                            setAdding(false);
                            setEditting("");
                            setadmform(SCHEMA_ADM_INITIAL_VALUES);
                          }}
                          style={{
                            borderRadius: 5,
                            borderColor: "transparent",
                            marginTop: 30,
                            backgroundColor: "transparent",
                            color: "black",
                            padding: "12px 0px",
                            height: "35px",
                            width: "150px",
                          }}
                        >
                          <h5
                            style={{
                              margin: 0,
                              fontWeight: "bold",
                              fontSize: "16px",
                              lineHeight: 0
                            }}
                          >
                            Cancelar
                          </h5>
                        </Button>
                      )}

                    </div>
                  </div>
                  <div className="col d-flex flex-column">
                    <AtomInput
                      icon={true}
                      label="E-mail *"
                      placeholder="E-mail *"
                      width="100%"
                      onChange={handleChange("email")}
                      value={values.email}
                      errors={errors.email}
                      touched={touched.email}
                      onBlur={handleBlur("email")}
                    />
                    <AtomInput
                      icon={true}
                      label="Celular *"
                      placeholder="Celular *"
                      width="100%"
                      onChange={handleChange("phone")}
                      value={values.phone}
                      errors={errors.phone}
                      touched={touched.phone}
                      onBlur={handleBlur("phone")}
                      mask="(99) 99999-9999"
                    />
                  </div>


                </div>
              )}
            </Formik>
          </div>
        </>
      ) : (
        <>
          <div
            style={{ padding: "0px 42px", marginBottom: "30px" }}
            className="w-100  row "
          >
            <div className="col text-start d-flex align-items-center">
              <h4
                style={{ fontSize: 18, color: "#3C3C3B", fontWeight: "bold" }}
                className="text-uppercase"
              >
                {"Usuários"}
              </h4>
            </div>
            <div className="col justify-content-end d-flex  align-items-center text-decoration-underline ">
              <h3
                onClick={() => {
                  setAdding(true);
                }}
                style={{
                  fontSize: 14,
                  cursor: "pointer",
                  color: "#3C3C3B",
                  fontWeight: "bold",
                }}
              >
                {/* + Novo Usuário */}
              </h3>
            </div>
          </div>

          <div style={{ padding: "0px 42px" }} className="w-100 row ">
            <BootstrapTable
              keyField="id"
              data={users}
              bordered={false}
              striped={true}
              hover={true}
              columns={columns}
              rowEvents={rowEvents}
              filter={filterFactory()}
            />
          </div>
        </>
      )}
    </>
  );
};

export default Usuarios;
