import ServiceApi from "../../api/index";

async function LoadSalesProposal() {
  const response = await ServiceApi.get(`all/oferta/venda`);
  return response.data;
}

async function Historico(dataInicial : string, dataFinal : string) {
  const response = await ServiceApi.get(`all/oferta/venda/historico/${dataInicial}/${dataFinal}`);
  return response.data;
} 
async function StatusVenda() {
  const response = await ServiceApi.get(`status-venda`);
  return response.data;
}

async function UpdateVenda(id : string, payload : any) {
  const response = await ServiceApi.put(`oferta/venda/update/${id}`, { ...payload },);
  return response.data;
}

async function DownloadImage (link : string) {
  const response = await ServiceApi.post(`/oferta/download`, { link: link });
  return response.data;
}


export { LoadSalesProposal , StatusVenda, Historico, UpdateVenda, DownloadImage};
