import React from "react";
import Logo from "../assets/images/logoInvertida.svg";

const Dashboard = () => {
  return (
    <>
      <img src={Logo} alt="" />

      <h1 style={{ fontSize: 22, fontWeight: "bold", marginTop: "20px" }}>
        Administrativo
      </h1>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <h1>Bem vindo!</h1>
      </div>
    </>
  );
};

export default Dashboard;
