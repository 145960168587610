import React from "react";
import LoginScreen from "./components/Login";
import "bootstrap/dist/css/bootstrap.min.css";
import { Routes, Route } from "react-router-dom";
import { PrivateRoute } from "./utils/PrivateRoute";
import NotFound from "./components/NotFound";
import RecoverPassword from "./components/RecoverPassword";
import Dashboard from "./components/Dashboard";
import Senha from "./components/Senha";
import Administrador from "./components/Administrador";
import Usuarios from "./components/Usuarios";
import PurchaseProposal from "./components/PurchaseProposal";
import SalesProposal from "./components/SalesProposal";
import Faq from "./components/Faq";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Stock from "./components/Stock";

require('react-bootstrap-table-next/dist/react-bootstrap-table2.min.css');

function App() {
  return (
    <div
      className="App"
      style={{ display: "flex", flexDirection: "column", height: "100%" }}
    >
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        theme="colored"
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Routes>
        <Route path="/" element={<LoginScreen />} />
        <Route path="/roger" element={<LoginScreen />} />
        <Route path="*" element={<NotFound />} />
        <Route path="v1/email/recover" element={<RecoverPassword />} />
        <Route
          path="dashboard"
          element={
            <PrivateRoute roles={["ADMIN", "COSTUMER"]} component={Dashboard} />
          }
        />
        <Route
          path="minhasenha"
          element={
            <PrivateRoute roles={["ADMIN", "COSTUMER"]} component={Senha} />
          }
        />
        <Route
          path="purchase-proposal"
          element={
            <PrivateRoute
              roles={["ADMIN", "COSTUMER"]}
              component={PurchaseProposal}
            />
          }
        />
        <Route
          path="stock"
          element={
            <PrivateRoute
              roles={["ADMIN", "COSTUMER"]}
              component={Stock}
            />
          }
        >
          <Route
            path=":idCar"
            element={
              <PrivateRoute
                roles={["ADMIN", "COSTUMER"]}
                component={Stock}
              />
            }
          />

        </Route>
        <Route
          path="sales-proposal"
          element={
            <PrivateRoute
              roles={["ADMIN", "COSTUMER"]}
              component={SalesProposal}
            />
          }
        />
        <Route
          path="adms"
          element={
            <PrivateRoute
              roles={["ADMIN", "COSTUMER"]}
              component={Administrador}
            />
          }
        />
        <Route
          path="usuarios"
          element={
            <PrivateRoute roles={["ADMIN", "COSTUMER"]} component={Usuarios} />
          }
        />
        <Route
          path="faq"
          element={
            <PrivateRoute roles={["ADMIN", "COSTUMER"]} component={Faq} />
          }
        />
      </Routes>
      {/* <p style={{ margin: 0, color: "white" }}>
        {`© ${new Date().getFullYear()} Grupo Carlos Cunha. Todos os direitos reservados.`}
      </p> */}
    </div>
  );
}

export default App;
