import {
  createTheme,
  FormControl,
  TextField,
  ThemeProvider,
} from "@mui/material";
import InputMask from 'react-input-mask';
import { blueGrey } from "@mui/material/colors";
import React from "react";

type Props = {
  errors?: string;
  icon: boolean;
  iconElement?: React.ReactNode;
  maxLength?: number;
  autoCapitalize?: "none" | undefined;
  onChange: any;
  onBlur?: any;
  placeholder: string;
  touched?: boolean;
  label: string;
  type?: string;
  value: string;
  width: string;
  disabled?: boolean;
  variant: "standard" | "filled" | "outlined";
  multiline: boolean;
  rows: number;
  mask?: string;
};

AtomInput.defaultProps = {
  variant: "standard",
  multiline: false,
  rows: 1,
};


function AtomInput(props: Props) {
  const theme = createTheme({
    palette: {
      primary: blueGrey,
    },
  });
  return (
    <>
      <FormControl style={{ marginTop: "0px", textAlign: "start", paddingBottom: 20, width: props.width }}>
        <ThemeProvider theme={theme}>
          {props.mask ? (
            <>
              <InputMask
                mask={props.mask}
                onChange={props.onChange}
                onBlur={props.onBlur}
                value={props.value}
              >
                {() => <TextField
                style={{ fontSize: "16px" }}
                type={props.type === "password" ? "password" : "text"}
                color="primary"
                maxRows='none'
                autoCapitalize="none"
                variant={props.variant}
                label={props.label}
                error={props.errors && props.touched ? true : false}
                disabled={props.disabled}
                multiline={props.multiline}
                rows={props.rows}
                />}
              </InputMask>
            </>
          ) : (
            <>
              <TextField
                style={{ fontSize: "16px" }}
                type={props.type === "password" ? "password" : "text"}
                color="primary"
                maxRows='none'
                autoCapitalize="none"
                onChange={props.onChange}
                onBlur={props.onBlur}
                variant={props.variant}
                value={props.value}
                label={props.label}
                error={props.errors && props.touched ? true : false}
                disabled={props.disabled}
                multiline={props.multiline}
                rows={props.rows}
              />
            </>
          )}
          

          {props.errors && props.touched && (
            <p className="pb-0 mb-0" style={{ color: "#D32F2F", float: "left", fontSize: "12px", paddingBottom: 2 }}>{props.errors}</p>
          )}
          {(!props.errors || !props.touched) && (
            <p className="pb-0 mb-0" style={{ color: "transparent", float: "left", fontSize: "12px", paddingBottom: 2 }}>_</p>
          )}
        </ThemeProvider>
      </FormControl>
    </>
  );
}

export { AtomInput };
