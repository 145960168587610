import React from "react";

const AccessDenied = () => {
  return (
    <div>
      <h1>Acesso negado!</h1>
    </div>
  );
};

export default AccessDenied;
