import ServiceApi from "../../api/index";
import { NewPassword, UserLogin, UserState } from "./userSlice";

async function Login(payload: UserLogin) {
  const response = await ServiceApi.post(`auth/sign-in`, payload, {
    headers: {
      "content-type": "application/json",
    },
  });
  if (response.data.userData.Role.name === "User") {
    throw new Error("não é admin");
  }
  return {
    email: payload.email,
    nome: response.data.userData.name,
    phone: response.data.userData.phone,
    tokenAuth: response.data.accessToken,
    id: response.data.user.uid,
    status: "idle",
  } as UserState;
}
async function Retrive(payload: NewPassword) {
  try {
    const response = await ServiceApi.post(`user-password`, payload, {
      headers: {
        Authorization: `Bearer ${payload.token}`,
        "content-type": "application/json",
        accept: "*/*",
        Connection: "keep-alive",
      },
    });
    return true;
  } catch {
    return false;
  }
}
async function Forgot(email: string) {
  try {
    const response = await ServiceApi.post(
      `auth/forgot`,
      { email },
      {
        headers: {
          "content-type": "application/json",
          accept: "*/*",
          Connection: "keep-alive",
        },
      }
    );
    return true;
  } catch {
    return false;
  }
}
async function CheckToken(payload: NewPassword) {
  try {
    const response = await ServiceApi.post(`access-token`, payload, {
      headers: {
        Authorization: `Bearer ${payload.token}`,
        "content-type": "application/json",
        accept: "*/*",
        Connection: "keep-alive",
      },
    });
    return true;
  } catch {
    return false;
  }
}

export { Login, Retrive, Forgot, CheckToken };
