import ServiceApi from "../../api/index";
import { UserNew } from "./userSlice";

/*  */
async function CreateUser(payload: UserNew) {
  const response = await ServiceApi.post(
    `auth/new-user`,
    { ...payload },
    {
      headers: {
        "content-type": "application/json",
      },
    }
  );
  return response.data;
}

async function LoadUser() {
  const response = await ServiceApi.get(`user`);
  console.log("===>>>", response)
  return response.data;
}

async function DeleteUser(id: string, token: string) {
  const response = await ServiceApi.delete(`/user/force-delete/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
      "content-type": "application/json",
      accept: "*/*",
      Connection: "keep-alive",
    },
  });
  return response.data;
}

async function UpdateUser(payload: any, id: string, token: string) {
  const response = await ServiceApi.put(
    `/user/${id}`,
    { ...payload },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "content-type": "application/json",
        accept: "*/*",
        Connection: "keep-alive",
      },
    }
  );
  return response.data;
}

async function ChangePassword(body : any) {
  const response = await ServiceApi.post(`change-password`, body);
  return response.data;
}

export { CreateUser, LoadUser, DeleteUser, UpdateUser, ChangePassword };
