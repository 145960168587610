import React, { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCar,
  faCommentsDollar,
  faHandHoldingUsd,
  faHome,
  faKey,
  faQuestionCircle,
  faSignOutAlt,
  faUser,
  faUserCircle,
  faUserShield,
} from "@fortawesome/free-solid-svg-icons";
import {
  NavLink,
  useLocation,
} from "react-router-dom";
import { useAppSelector } from "../app/hooks";
import { logoff, selectUser } from "../features/user/userSlice";
import { useDispatch } from "react-redux";
const Menu = () => {
  const location = useLocation();
  const user = useAppSelector(selectUser);
  const dispatch = useDispatch();
  useEffect(() => {
    console.log(location.pathname);
  }, [location]);
  return (
    <div
      style={{
        zIndex: 2,
        boxShadow: "6px 3px 6px #00000066",
        position: 'fixed',
        top: 0,
        left: 0,
        height: "100%",
        width: '200px',
        backgroundColor: '#3C3C3B',
        padding: 0,
        // overflow: "auto"
      }}
      className="col-md-2">
      <div className="row mb-0 mt-2"
        style={{
          marginBottom: -20,
          alignItems: "center",
        }}>
        <div className="col-1"></div>
        <div className="col mt-3 pr-0 mr-0 d-flex justify-content-start">
          <FontAwesomeIcon
            size="3x"
            color={location.pathname === "/amizade" ? "#ECB800" : "white"}
            icon={faUserCircle}
          />
          <div
            style={{ marginLeft: 11 }}
            className="h-100 pl-0 col align-items-center text-white text-start  "
          >
            <p className="mt-2 mb-0" style={{ fontSize: "11px" }}>
              Olá,
            </p>
            <p style={{ fontSize: "16px" }}>{user.nome.split(" ")[0]}</p>
          </div>
        </div>
      </div>
      <NavLink style={{ textDecoration: "none" }} to="/dashboard">
        <div className=" mt-3 row">
          <div className="col-1"></div>
          <div
            style={{ alignSelf: "center", cursor: "pointer" }}
            className="col d-flex justify-content-start align-items-center"
          >
            <FontAwesomeIcon
              size="1x"
              color={location.pathname === "/dashboard" ? "#ECB800" : "white"}
              icon={faHome}
            />
            <span
              style={{
                fontSize: 14,
                marginLeft: 8,
                fontWeight: "bold",
                color: location.pathname === "/dashboard" ? "#ECB800" : "white",
              }}
            >
              Home
            </span>
          </div>
        </div>
      </NavLink>
      <NavLink style={{ textDecoration: "none" }} to="/stock">
        <div className="mt-4 row">
          <div className="col-1"></div>
          <div
            style={{ alignSelf: "center", cursor: "pointer" }}
            className="col d-flex justify-content-start align-items-center"
          >
            <FontAwesomeIcon
              size="1x"
              color={location.pathname.includes("/stock") ? "#ECB800" : "white"}
              icon={faCar}
            />{" "}
            <span
              style={{
                fontSize: 14,
                marginLeft: 8,
                fontWeight: "bold",
                color: location.pathname.includes("/stock")  ? "#ECB800" : "white",
              }}
            >
               Estoque
            </span>
          </div>
        </div>
      </NavLink>

      <NavLink style={{ textDecoration: "none" }} to="/purchase-proposal">
        <div className="mt-4 row">
          <div className="col-1"></div>
          <div
            style={{ alignSelf: "center", cursor: "pointer" }}
            className="col d-flex justify-content-start align-items-center"
          >
            <FontAwesomeIcon
              size="1x"
              color={location.pathname === "/purchase-proposal" ? "#ECB800" : "white"}
              icon={faCommentsDollar}
            />{" "}
            <span
              style={{
                fontSize: 14,
                marginLeft: 8,
                fontWeight: "bold",
                color: location.pathname === "/purchase-proposal" ? "#ECB800" : "white",
              }}
            >
               Propostas de compra
            </span>
          </div>
        </div>
      </NavLink>

      <NavLink style={{ textDecoration: "none" }} to="/sales-proposal">
        <div className="mt-4 row">
          <div className="col-1"></div>
          <div
            style={{ alignSelf: "center", cursor: "pointer" }}
            className="col d-flex justify-content-start align-items-center"
          >
            <FontAwesomeIcon
              size="1x"
              color={location.pathname === "/sales-proposal" ? "#ECB800" : "white"}
              icon={faHandHoldingUsd}
            />{" "}
            <span
              style={{
                fontSize: 14,
                marginLeft: 8,
                fontWeight: "bold",
                color: location.pathname === "/sales-proposal" ? "#ECB800" : "white",
              }}
            >
               Propostas de venda
            </span>
          </div>
        </div>
      </NavLink>
      <NavLink style={{ textDecoration: "none" }} to="/adms">
        <div className="mt-4 row">
          <div className="col-1"></div>
          <div
            style={{ alignSelf: "center", cursor: "pointer" }}
            className="col d-flex justify-content-start align-items-center"
          >
            <FontAwesomeIcon
              size="1x"
              color={location.pathname === "/adms" ? "#ECB800" : "white"}
              icon={faUserShield}
            />{" "}
            <span
              style={{
                fontSize: 14,
                marginLeft: 8,
                fontWeight: "bold",
                color: location.pathname === "/adms" ? "#ECB800" : "white",
              }}
            >
              Administradores
            </span>
          </div>
        </div>
      </NavLink>
      <NavLink style={{ textDecoration: "none" }} to="/usuarios">
        <div className="mt-4 row">
          <div className="col-1"></div>
          <div
            style={{ alignSelf: "center", cursor: "pointer" }}
            className="col d-flex justify-content-start align-items-center"
          >
            <FontAwesomeIcon
              size="1x"
              color={location.pathname === "/usuarios" ? "#ECB800" : "white"}
              icon={faUser}
            />
            <span
              style={{
                fontSize: 14,
                marginLeft: 8,
                fontWeight: "bold",
                color: location.pathname === "/usuarios" ? "#ECB800" : "white",
              }}
            >
              Usuários
            </span>
          </div>
        </div>
      </NavLink>
      <NavLink style={{ textDecoration: "none" }} to="/faq">
        <div className="mt-4 row">
          <div className="col-1"></div>
          <div
            style={{ alignSelf: "center", cursor: "pointer" }}
            className="col d-flex justify-content-start align-items-center"
          >
            <FontAwesomeIcon
              size="1x"
              color={location.pathname === "/faq" ? "#ECB800" : "white"}
              icon={faQuestionCircle}
            />
            <span
              style={{
                fontSize: 14,
                marginLeft: 8,
                fontWeight: "bold",
                color: location.pathname === "/faq" ? "#ECB800" : "white",
              }}
            >
              FAQ
            </span>
          </div>
        </div>
      </NavLink>
      <NavLink style={{ textDecoration: "none" }} to="/minhasenha">
        <div className="mt-4 row">
          <div className="col-1"></div>
          <div
            style={{ alignSelf: "center", cursor: "pointer" }}
            className="col d-flex justify-content-start align-items-center"
          >
            <FontAwesomeIcon
              size="1x"
              color={location.pathname === "/minhasenha" ? "#ECB800" : "white"}
              icon={faKey}
            />
            <span
              style={{
                fontSize: 14,
                marginLeft: 8,
                fontWeight: "bold",
                color:
                  location.pathname === "/minhasenha" ? "#ECB800" : "white",
              }}
            >
              Minha senha
            </span>
          </div>
        </div>
      </NavLink>
      <div className="mt-4 row">
        <div className="col-1"></div>
        <div
          onClick={() => {
            dispatch(logoff(""));
          }}
          style={{ alignSelf: "center", cursor: "pointer" }}
          className="col d-flex justify-content-start align-items-center"
        >
          <FontAwesomeIcon
            size="1x"
            color={location.pathname === "/amizade" ? "#ECB800" : "white"}
            icon={faSignOutAlt}
          />
          <span
            style={{
              fontSize: 14,
              marginLeft: 8,
              fontWeight: "bold",
              color: location.pathname === "/amizade" ? "#ECB800" : "white",
            }}
          >
            Sair
          </span>
        </div>
      </div>
    </div>
  );
};

export default Menu;
