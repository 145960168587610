import ServiceApi from "../../api/index";

async function LoadStock() {
  const response = await ServiceApi.get(`all/veiculo`);
  return response.data;
}

async function UploadLaudo(formData: FormData, id: string) {
  const response = await ServiceApi.post(`upload-file/${id}`, formData, {
    headers: {
      'Content-Type': `multipart/form-data`
    }
  })
  return response.data;
}

async function DownloadLaudo(body: any) {
  return await ServiceApi.post('veiculo/download', body);
}

export { LoadStock, UploadLaudo, DownloadLaudo };
