import './Login.css';
import React, { useState, useEffect } from "react";
import Logo from "../assets/images/logoInvertida.svg";
import { Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { selectUser, setUser } from "../features/user/userSlice";
import { Formik } from "formik";
import * as Yup from "yup";
import { AtomInput } from "../utils/ui/input/AtomInput";
import { useAppSelector } from "../app/hooks";
import { useNavigate } from "react-router-dom";
import { Forgot, Login } from "../features/user/userApi";
import { toast } from "react-toastify";
import ToastMessage from "./Toasts";

const LoginScreen = () => {
  const [forgot, setForgot] = useState(false);
  const [sended, setSended] = useState(false);
  const dispatch = useDispatch();
  const loginnoapp = async (email: string, password: string) => {
    try {
      const response = await Login({ email, password });
      dispatch(setUser(response));
    } catch (e) {
      console.log(e)
      toast.error(
        <ToastMessage title="Erro" msg="Verifique seu e-mail ou senha" />,
        {
          position: "bottom-center",
          autoClose: 500000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
    }
  };
  const forgotpassword = async (email: string) => {
    try {
      console.log(email);
      setSended(await Forgot(email));
    } catch {
      throw new Error("error");
    }
  };

  const navigate = useNavigate();

  const user = useAppSelector(selectUser);
  const SCHEMA_LOGIN_INITIAL_VALUES = {
    email: "",
    password: "",
  };
  const SCHEMA_LOGIN = Yup.object().shape({
    email: Yup.string().email("E-mail inválido").required("Campo obrigatório"),
    password: Yup.string().required("Campo obrigatório"),
  });
  const SCHEMA_FORGOT = Yup.object().shape({
    email: Yup.string().email("E-mail inválido").required("Campo obrigatório"),
  });
  const SCHEMA_FORGOT_INITIAL_VALUES = {
    email: "",
  };
  useEffect(() => {
    if (user.tokenAuth) {
      navigate("dashboard");
    }
  }, [user]);
  const [loginform] = useState(SCHEMA_LOGIN_INITIAL_VALUES);
  const [forgotform] = useState(SCHEMA_LOGIN_INITIAL_VALUES);

  return (
    <>
      <div
        className='login'
        style={{
          display: "flex",
          // overflow: "hidden",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "52px 0px 52px 52px",
          marginTop: 20,
          width: "100%",
          backgroundColor: "white",
        }}
      >
        <div
          className="row"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            width: "100%",
            minHeight: "100%",
          }}
        >
          <div
            className="mx-0 col-md-5"
            style={{
              padding: "0 5%",
              display: "flex",
              flexDirection: "column",
              marginTop: 50,
              marginBottom: 50,
            }}
          >
            <div>
              <img style={{ maxHeight: 44 }} src={Logo} />
            </div>
            {!forgot ? (
              <>
                <h1
                  style={{
                    fontSize: 18,
                    fontWeight: "bold",
                    marginTop: 30,
                  }}
                >
                  LOGIN
                </h1>

                <div className="mx-0 px-0 w-100 text-left">
                  <h5
                    style={{
                      fontWeight: "light",
                      fontSize: 16,
                      marginTop: 13,
                      marginBottom: 50,
                      textAlign: 'center'
                    }}
                  >
                    Preencha os campos abaixo para acessar sua conta
                  </h5>
                </div>
                <Formik
                  initialValues={loginform}
                  validationSchema={SCHEMA_LOGIN}
                  onSubmit={async (values, { setSubmitting }) => {
                    try {
                      await loginnoapp(values.email, values.password);
                    } catch (e) {
                      setSubmitting(false);
                    }
                  }}
                >
                  {({
                    errors,
                    handleChange,
                    submitForm,
                    touched,
                    isSubmitting,
                    handleBlur,
                    values,
                  }) => (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <AtomInput
                        icon={true}
                        label="E-mail *"
                        placeholder="E-mail *"
                        width="100%"
                        onChange={handleChange("email")}
                        value={values.email}
                        errors={errors.email}
                        touched={touched.email}
                        onBlur={handleBlur("email")}
                      />
                      <AtomInput
                        icon={true}
                        label="Senha *"
                        placeholder="Senha *"
                        type="password"
                        width="100%"
                        onChange={handleChange("password")}
                        value={values.password}
                        errors={errors.password}
                        touched={touched.password}
                        onBlur={handleBlur("password")}
                      />
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row-reverse",
                        }}
                      >
                        <h5
                          style={{
                            marginTop: 0,
                            cursor: "pointer",
                            textDecoration: "underline",
                            fontSize: "16px",
                            fontWeight: "bold",
                          }}
                          onClick={() => {
                            setForgot(true);
                          }}
                        >
                          Esqueci minha senha
                        </h5>
                      </div>
                      <Button
                        disabled={isSubmitting}
                        onClick={() => {
                          submitForm();
                        }}
                        style={{
                          borderRadius: 5,
                          marginTop: 30,
                          backgroundColor: "#3C3C3B",
                          color: "white",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          textAlign: "center",
                          padding: "10px 0px",
                          width: "180px",
                          height: "35px",
                          fontWeight: "bold",
                          fontSize: "16px",
                        }}
                      >
                        Entrar
                      </Button>
                    </div>
                  )}
                </Formik>
              </>
            ) : (
              <>
                {sended ? (
                  <>
                    {" "}
                    <h1
                      style={{
                        fontSize: 18,
                        fontWeight: "bolder",
                        marginTop: 30,
                        textTransform: "uppercase",
                      }}
                    >
                      Esqueci minha senha
                    </h1>
                    <h5
                      style={{
                        fontWeight: "light",
                        fontSize: 18,
                        marginTop: 30,
                      }}
                    >
                      Se você estiver cadastrado na plataforma, em breve você
                      receberá um e-mail com as informações para alterar sua
                      senha.
                    </h5>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <Button
                        onClick={() => {
                          setSended(false);
                          setForgot(false);
                        }}
                        style={{
                          borderRadius: 5,
                          marginTop: 30,
                          backgroundColor: "#3C3C3B",
                          color: "white",
                          padding: "10px 0px",
                          height: "35px",
                          width: "150px",
                        }}
                      >
                        <h5
                          style={{
                            margin: 0,
                            fontWeight: "bold",
                            fontSize: "16px",
                            lineHeight: 0
                          }}
                        >
                          Voltar para login
                        </h5>
                      </Button>
                    </div>
                  </>
                ) : (
                  <>
                    <h1
                      style={{
                        fontSize: 18,
                        fontWeight: "bold",
                        marginTop: 30,
                      }}
                    >
                      ESQUECI MINHA SENHA
                    </h1>
                    <h5
                      style={{
                        fontWeight: "light",
                        fontSize: 16,
                        marginTop: 13,
                        marginBottom: 50,
                        textAlign: 'center'
                      }}
                    >
                      Informe seu e-mail cadastrado para alterar sua senha
                    </h5>
                    <Formik
                      initialValues={forgotform}
                      validationSchema={SCHEMA_FORGOT}
                      onSubmit={async (values, { setSubmitting }) => {
                        try {
                          await forgotpassword(values.email);
                          setSubmitting(false);
                        } catch (e) {
                          setSubmitting(false);
                        }
                      }}
                    >
                      {({
                        errors,
                        handleChange,
                        submitForm,
                        touched,
                        handleBlur,
                        isSubmitting,
                        values,
                      }) => (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <AtomInput
                            icon={true}
                            label="E-mail *"
                            placeholder="E-mail *"
                            width="100%"
                            onChange={handleChange("email")}
                            value={values.email}
                            errors={errors.email}
                            touched={touched.email}
                            onBlur={handleBlur("email")}
                          />

                          <Button
                            onClick={() => {
                              submitForm();
                            }}
                            disabled={isSubmitting}
                            style={{
                              borderRadius: 5,
                              marginTop: 30,
                              backgroundColor: "#3C3C3B",
                              color: "white",
                              padding: "10px 0px",
                              height: "35px",
                              width: "150px",
                            }}
                          >
                            <h5
                              style={{
                                margin: 0,
                                fontWeight: "bold",
                                fontSize: "16px",
                                lineHeight: 0
                              }}
                            >
                              Enviar
                            </h5>
                          </Button>
                        </div>
                      )}
                    </Formik>
                  </>
                )}
              </>
            )}
          </div>
          <div className="mt-2 mt-md-0 mx-0 px-0 col-md-7 login-foto" />
        </div>
      </div>
      <p className="mt-2 mb-2" style={{ fontSize: 11, color: "white" }}>
        {`© ${new Date().getFullYear()} Grupo Carlos Cunha. Todos os direitos reservados.`}
      </p>
    </>
  );
};

export default LoginScreen;
