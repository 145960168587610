import {
  createTheme,
  FormControl,
  TextField,
  ThemeProvider,
} from "@mui/material";
import { blueGrey } from "@mui/material/colors";
import React from "react";

type Props = {
  children: React.ReactNode;
  errors?: string;
  onChange: any;
  onBlur?: any;
  placeholder: string;
  touched?: boolean;
  label: string;
  type?: string;
  value: string;
  width: string;
  disabled?: boolean;
  variant: "standard" | "filled" | "outlined";
};

AtomSelect.defaultProps = {
  variant: "standard",
};


function AtomSelect(props: Props) {
  const theme = createTheme({
    palette: {
      primary: blueGrey,
    },
  });
  return (
    <>
      <FormControl style={{ marginTop: "0px", textAlign: "start", paddingBottom: 20, width: props.width }}>
        <ThemeProvider theme={theme}>
          <TextField
            select
            style={{ fontSize: "16px" }}
            type={props.type === "password" ? "password" : "text"}
            color="primary"
            maxRows='none'
            autoCapitalize="none"
            onChange={props.onChange}
            onBlur={props.onBlur}
            variant={props.variant}
            value={props.value}
            label={props.label}
            error={props.errors && props.touched ? true : false}
            disabled={props.disabled}
          >
            {props.children}
          </TextField>          

          {props.errors && props.touched && (
            <p className="pb-0 mb-0" style={{ color: "#D32F2F", float: "left", fontSize: "12px", paddingBottom: 2 }}>{props.errors}</p>
          )}
          {(!props.errors || !props.touched) && (
            <p className="pb-0 mb-0" style={{ color: "transparent", float: "left", fontSize: "12px", paddingBottom: 2 }}>_</p>
          )}
        </ThemeProvider>
      </FormControl>
    </>
  );
}

export { AtomSelect };
