import './RecoverPassword.css';
import React, { useEffect, useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import Logo from "../assets/images/logoInvertida.svg";
import { Button } from "react-bootstrap";
import { CheckToken, Retrive } from "../features/user/userApi";
import * as Yup from "yup";
import { Formik } from "formik";
import { AtomInput } from "../utils/ui/input/AtomInput";
import jwt from "jsonwebtoken";

const RecoverPassword = (props: any) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const paramValue = searchParams.get("token");
  const [token, setToken] = useState(searchParams.get("token"));
  const [sent, setSent] = useState(false);
  const [success, setSuccess] = useState(false);
  const [tokenError, setTokenError] = useState(false);
  const [initialLoad, setInitialLoad] = useState(true);

  const checktoken = async (token: string) => {
    if (token) {
      try {
        setSent(true);
        const resp = await CheckToken({
          newPassword: token,
          confirmNewPassword: token,
          token,
        });
        setTokenError(!resp);
      } catch {
        throw new Error("error");
      }
    } else {
      throw new Error("error");
    }
  };

  useEffect(() => {
    const init = async () => {
      try {
        if (paramValue) {
          jwt.verify(paramValue, "wBjtrGryCSP5rtedHYhUBaYCcVNkQbWL");
          await checktoken(paramValue);
        }
      } catch {
        setTokenError(true);
      }

      setToken(paramValue);
      setInitialLoad(false);
    };

    if (initialLoad) {
      init();
    }
  }, [initialLoad, paramValue]);

  const SCHEMA_INITIAL_VALUES = {
    password: "",
    password1: "",
  };
  const SCHEMA = Yup.object().shape({
    password: Yup.string()
      .min(6, "Sua nova senha deve conter no mínimo 6 caracteres")
      .required("Campo obrigatório"),
    password1: Yup.string()
      .min(6, "Sua nova senha deve conter no mínimo 6 caracteres")
      .required("Campo obrigatório")
      .oneOf([Yup.ref("password")], "As senhas devem ser iguais"),
  });
  const [passwords] = useState(SCHEMA_INITIAL_VALUES);
  const retrivepassword = async (values: any) => {
    if (token) {
      try {
        setSent(true);
        const resp = await Retrive({
          newPassword: values.password,
          confirmNewPassword: values.password1,
          token,
        });

        setSuccess(resp);
      } catch {
        throw new Error("error");
      }
    } else {
      throw new Error("error");
    }
  };
  return (
    <>
      <div
        className='recover'
        style={{
          display: "flex",
          // overflow: "hidden",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "52px 0px 52px 52px",
          marginTop: 20,
          width: "100%",
          backgroundColor: "white",
        }}
      >
        <div
          className="row"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            width: "100%",
            minHeight: "100%",
          }}
        >
          <div
            className="px-5 mx-0 col-md-5"
            style={{
              padding: "0 5%",
              display: "flex",
              flexDirection: "column",
              marginTop: 50,
              marginBottom: 50,
              paddingBottom: 300,
            }}
          >
            <div>
              <img src={Logo} alt="" />
            </div>
            {!initialLoad &&
              <>
                {tokenError ? (
                  <>
                    <h1
                      style={{
                        fontSize: 18,
                        fontWeight: "bold",
                        marginTop: 30,
                      }}
                    >
                      SEU LINK EXPIROU
                    </h1>
                    <h5 style={{
                      fontWeight: "light",
                      fontSize: 16,
                      marginTop: 13,
                      marginBottom: 50,
                      textAlign: 'center'
                    }}>
                      Para concluir esta ação, solicite um novo link.
                    </h5>
                  </>
                ) : (
                  <>
                    {sent && success ? (
                      <>
                        <h1
                          style={{
                            fontSize: 18,
                            fontWeight: "bold",
                            marginTop: 30,
                          }}
                        >
                          ALTERAR MINHA SENHA
                        </h1>
                        <h5 style={{
                          fontWeight: "light",
                          fontSize: 16,
                          marginTop: 13,
                          marginBottom: 50,
                          textAlign: 'center'
                        }}>
                          Sua senha foi alterada com sucesso.
                          <br />
                          Você já pode se autenticar com a sua nova senha.
                        </h5>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <Button
                            onClick={() => {
                              navigate('/');
                            }}
                            style={{
                              borderRadius: 5,
                              marginTop: 30,
                              backgroundColor: "#3C3C3B",
                              color: "white",
                              padding: "10px 0px",
                              height: "35px",
                              width: "250px",
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                          >
                            <h5
                              style={{
                                margin: 0,
                                fontWeight: "bold",
                                fontSize: "16px",
                                lineHeight: 0
                              }}
                            >
                              Voltar para login
                            </h5>
                          </Button>
                        </div>
                      </>
                    ) : (
                      <>
                        <h1
                          style={{
                            fontSize: 18,
                            fontWeight: "bold",
                            marginTop: 30,
                          }}
                        >
                          ALTERAR MINHA SENHA
                        </h1>
                        <h5 style={{
                          fontWeight: "light",
                          fontSize: 16,
                          marginTop: 13,
                          marginBottom: 50,
                          textAlign: 'center'
                        }}>
                          Utilize os campos abaixo para alterar sua senha. <br />
                          Sua nova senha deve conter no mínimo 6 caracteres.
                        </h5>
                        <Formik
                          initialValues={passwords}
                          validationSchema={SCHEMA}
                          onSubmit={async (values, { setSubmitting }) => {
                            try {
                              await retrivepassword(values);
                              setSubmitting(false);
                            } catch (e) {
                              setSubmitting(false);
                            }
                          }}
                        >
                          {({
                            errors,
                            handleChange,
                            submitForm,
                            touched,
                            isSubmitting,
                            handleBlur,
                            values,
                          }) => (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <AtomInput
                                icon={true}
                                label="Nova senha *"
                                placeholder="Nova senha *"
                                type={"password"}
                                width="100%"
                                onChange={handleChange("password")}
                                value={values.password}
                                errors={errors.password}
                                touched={touched.password}
                                onBlur={handleBlur("password")}
                              />
                              <AtomInput
                                icon={true}
                                label="Confirmar senha *"
                                placeholder="Confirmar senha *"
                                type={"password"}
                                width="100%"
                                onChange={handleChange("password1")}
                                value={values.password1}
                                errors={errors.password1}
                                touched={touched.password1}
                                onBlur={handleBlur("password1")}
                              />
                              <Button
                                disabled={isSubmitting}
                                onClick={() => {
                                  submitForm();
                                }}
                                style={{
                                  borderRadius: 5,
                                  marginTop: 30,
                                  backgroundColor: "#3C3C3B",
                                  color: "white",
                                  padding: "10px 0px",
                                  height: "35px",
                                  width: "150px",
                                }}
                              >
                                <h5
                                  style={{
                                    margin: 0,
                                    fontWeight: "bold",
                                    fontSize: "16px",
                                    lineHeight: 0
                                  }}
                                >
                                  Confirmar
                                </h5>
                              </Button>
                            </div>
                          )}
                        </Formik>
                      </>
                    )}
                  </>
                )}
              </>
            }
          </div>
          <div className="mt-2 mt-md-0 mx-0 px-0 col-md-7 recover-foto" />
        </div>
      </div>
      <p className="mt-2 mb-2" style={{ fontSize: 11, color: "white" }}>
        {`© ${new Date().getFullYear()} Grupo Carlos Cunha. Todos os direitos reservados.`}
      </p>
    </>
  );
};

export default RecoverPassword;
