import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { createTheme } from "@mui/material";
import { Formik } from "formik";
import * as Yup from "yup";
import { AtomInput } from "../utils/ui/input/AtomInput";
import { useAppSelector } from "../app/hooks";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, {
  textFilter,
} from "react-bootstrap-table2-filter";

import { StatusVenda } from "../features/proposal/salesProposalApi";
import { LoadStock, UploadLaudo, DownloadLaudo } from "../features/stock/stockApi";
import { blueGrey } from "@mui/material/colors";
import { selectUser } from "../features/user/userSlice";
import { toast } from "react-toastify";
import ToastMessage from "./Toasts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMinusCircle
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate, useParams } from "react-router-dom";


const Stock = (props: any) => {
  const user = useAppSelector(selectUser);
  const navigate = useNavigate();
  const params = useParams();

  const SCHEMA_INITIAL_VALUES = {
    marca: "",
    modelo: "",
    versao: "",
    valor: "",
    ano: "",
    km: "",
    cambio: "",
    combustivel: "",
    finalPlaca: "",
    cor: "",
    sobreVeiculo: "",
    itens: "",
    laudo: "",
  };
  const SCHEMA_ADM = Yup.object().shape({
  });



  const [stockList, setStockList] = useState([]);
  const [listStatus, setListStatus] = useState([]);
  const [stockForm, setStockForm] = useState(SCHEMA_INITIAL_VALUES);
  const [statenow, setStatenow] = useState(false);
  const [isEditting, setEditting] = useState("");
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [isSearch, setIsSearch] = useState(false);
  const [age, setAge] = React.useState("");
  const [rowSelected, setRowSelected] = useState<any>({});

  const dataAtual = new Date();
  const listYears = [dataAtual.getFullYear(), dataAtual.getFullYear() - 1, dataAtual.getFullYear() - 2];
  const listMonths = ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"];
  const columns = [
    {
      dataField: "veiculo",
      headerStyle: {
        backgroundColor: "#989898",
        border: "none",
        borderRadius: "5px 0 0 0",
      },
      text: "Veículo",
      filter: textFilter({
        placeholder: "Pesquisar",
        style: { height: 30, marginTop: 5, marginBottom: 5 },
      }),
      headerFormatter: priceFormatter,
      style: {
        width: "100%",
        cursor: "pointer",
        border: "none",
        "text-align": "start",
        paddingLeft: 30,
      },
      sort: true
    },
    {
      dataField: "valueFormated",
      text: "Valor",
      headerStyle: {
        backgroundColor: "#989898",
        borderColor: "#989898",
        borderRadius: "0 0 0 0",
      },
      filter: textFilter({
        placeholder: "Pesquisar",
        style: { height: 30, marginTop: 5, marginBottom: 5 },
      }),
      headerFormatter: priceFormatter,
      style: {
        cursor: "pointer",
        border: "none",
        "text-align": "start",
        paddingLeft: 30,
      },
      sort: true
    },
    {
      dataField: "anoFormated",
      text: "Ano",
      headerStyle: {
        backgroundColor: "#989898",
        borderColor: "#989898",
        borderRadius: "0 5px 0 0",
      },
      filter: textFilter({
        placeholder: "Pesquisar",
        style: { height: 30, marginTop: 5, marginBottom: 5 },
      }),
      headerFormatter: priceFormatter,
      style: {
        cursor: "pointer",
        border: "none",
        "text-align": "start",
        paddingLeft: 30,
      },
      sort: true
    },
  ];

  const [fileSelected, setFileSelected] = useState<any>({});
  const [nameFileSelected, setNameFileSelected] = useState("");
  const [caminhoFile, setCaminhoFile] = useState("");

  useEffect(() => {
    // setStockForm(SCHEMA_INITIAL_VALUES);
    loadStock();
  }, []);

  const loadStock = async () => {
    try {
      setStockList([]);
      const resp = await LoadStock();

      let list = resp.data;
      list.forEach((element: any) => {
        element.anoFormated = element.AnoFabricacao + "/" + element.AnoModelo;
        element.valueFormated = formatValuePrice(Number(element.Preco));
        element.veiculo = element.Marca.nome + " " + element.Modelo.nome + " " + element.Versao.nome;
      });


      if (params.idCar) {
        let obj = list.find((obj: any) => obj.id == params.idCar)
        if (obj) {
          selectRow(obj)
        }
      } else {
        setEditting("");
        setStockForm(SCHEMA_INITIAL_VALUES);
      }

      setStockList(list);
    } catch {
      console.log("error");
    }
  };

  const getStatusVenda = async () => {
    try {
      const resp = await StatusVenda();
      setListStatus(resp.data);
    } catch {
      console.log("error");
    }
  };


  const theme = createTheme({
    palette: {
      primary: blueGrey,
    },
  });

  function priceFormatter(
    column: any,
    colIndex: any,
    { sortElement, filterElement }: any
  ): any {
    return (
      <div
        style={{
          color: "#3C3C3B",
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
          fontSize: "16px",
          fontWeight: "bold",
          marginLeft: 20,
          width: "100%",
        }}
      >
        {sortElement}
        {column.text}
        {filterElement}
      </div>
    );
  }

  const formatValuePrice = (value: number) => {
    return `R$ ${value.toLocaleString('pt-br', { minimumFractionDigits: 2 })}`
  }


  const downloadLaudo = async () => {
    const res = await DownloadLaudo({ link: rowSelected.urlAnexo });

    const base64 = res.data;

    const linkSource = `data:application/pdf;base64,${base64}`;
    const downloadLink = document.createElement("a");
    const fileName = "laudo veicular.pdf";
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }


  const selectRow = (row: any) => {
    let itensLine = "";
    clearFile()

    if (row.Opcionais.length) {
      itensLine = row.Opcionais[0].nome;
    }

    for (let index = 1; index < row.Opcionais.length; index++) {
      const element = row.Opcionais[index];
      itensLine = itensLine + " \n" + element.nome
    }

    setStockForm({
      marca: row.Marca.nome,
      modelo: row.Modelo.nome,
      versao: row.Versao.nome,
      valor: row.valueFormated,
      ano: row.anoFormated,
      km: Number(row.Km).toLocaleString('pt-br'),
      cambio: row.Transmissao.nome,
      combustivel: row.Combustivel.nome,
      finalPlaca: row.Placa.substring(row.Placa.length - 1),
      cor: row.Cor,
      sobreVeiculo: row.Observacao,
      itens: itensLine,
      laudo: row.urlAnexo,
    });
    setRowSelected(row);
    setEditting(row.id);

    window.scrollTo(0, 0)
  }

  const openImage = async (url : string) => {
    let tab = window.open();
    if (tab) {
      tab.location.href = url;
    }
  }

  const rowEvents = {
    onClick: async (e: any, row: any, rowIndex: any) => {
      selectRow(row)
    },
  };

  const formatDateEn = (date: Date) => {
    const day = String(date.getDate()).length > 1 ? date.getDate() : "0" + date.getDate()
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).length > 1 ? (date.getMonth() + 1) : "0" + (date.getMonth() + 1);

    return `${year}-${month}-${day}`

  }



  const renderTitleBloco = (value: string, t?: any) => {
    return (
      <>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            marginLeft: "-42px",
            width: "100%",
            marginBottom: "13px"
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: 30,
              backgroundColor: "#bcbcbc",
              height: "32px",
            }}
          >
            <span
              style={{
                padding: "0 30px 0 42px",
                fontWeight: "bold",
                fontSize: 16,
                color: "#3C3C3B",
              }}
            >
              {value}
            </span>
          </div>
        </div>
      </>

    )
  }

  const fileChange = (event: any) => {

    event.stopPropagation();
    event.preventDefault();
    if (event?.target?.files[0]) {
      setNameFileSelected(event.target.files[0].name);
      setFileSelected(event.target.files[0])

      const inputFile: any = document?.getElementById("laudo-file");
      if (inputFile) {
        setCaminhoFile(inputFile.value)
      }
    }

  }

  const onDrop = (event: any) => {
    event.stopPropagation();
    event.preventDefault();
    if (event?.dataTransfer?.files[0]) {
      setNameFileSelected(event.dataTransfer.files[0].name);
      setFileSelected(event.dataTransfer.files[0])

      const inputFile: any = document?.getElementById("laudo-file");
      if (inputFile) {

        inputFile.files = event.dataTransfer.files

        setCaminhoFile(inputFile.value)
      }
    }
  }


  const onDragOver = (e: any) => {

    let event = e as Event;
    event.stopPropagation();
    event.preventDefault();


  }

  const upload = async () => {
    const inputFile: any = document?.getElementById("laudo-file");
    if (inputFile) {
      let formData = new FormData();
      formData.append("file", inputFile.files[0]);

      try {
        await UploadLaudo(formData, rowSelected.id);
        loadStock();
        window.scrollTo(0, 0)

        setNameFileSelected("")
        toast.success(
          <ToastMessage
            title="Sucesso"
            msg={"Envio do laudo realizado com sucesso"}
          />,
          {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );

      } catch (error) {

      }
    }


  }

  const clearFile = () => {
    const inputFile: any = document?.getElementById("laudo-file");
    if (inputFile) {

      inputFile.value = "";
    }
    setFileSelected("")
    setNameFileSelected("")
    setCaminhoFile("")
  }

  return (
    <>
      {isEditting ? (

        <>
          <div className="w-100 row" style={{ padding: "0px 42px" }}>
            <div className="col text-start d-flex align-items-center">
              <h4
                style={{ fontSize: 18, color: "#3C3C3B", fontWeight: "bold" }}
                className="text-uppercase"
              >
                {`ESTOQUE > EDITAR`}
              </h4>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",

              width: "100%",
            }}
          >


            <div style={{ padding: "0px 42px" }} className="w-100 row">
              <Formik
                initialValues={stockForm}
                validationSchema={SCHEMA_ADM}
                onSubmit={async (values, { setSubmitting }) => {
                  upload()

                }}
              >
                {({
                  errors,
                  handleChange,
                  submitForm,
                  touched,
                  isSubmitting,
                  handleBlur,
                  values,
                }) => (
                  <>

                    {renderTitleBloco("Dados do veículo")}
                    <div className="row">
                      <div className="col d-flex flex-column text-start">
                        <AtomInput
                          icon={true}
                          label="Veículo"
                          placeholder="Veículo"
                          width="100%"
                          value={values.marca + ' ' + values.modelo + ' ' + values.versao}
                          onBlur={handleBlur("marca")}
                          onChange={handleChange("marca")}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col d-flex flex-column text-start">
                        {/* <AtomInput
                          icon={true}
                          label="Marca"
                          placeholder="Marca"
                          width="100%"
                          value={values.marca}
                          onBlur={handleBlur("marca")}
                          onChange={handleChange("marca")}
                          disabled
                        /> */}
                        {/* <AtomInput
                          icon={true}
                          label="Versão"
                          placeholder="Versão"
                          width="100%"
                          value={values.versao}
                          onBlur={handleBlur("versao")}
                          disabled
                          onChange={handleChange("versao")}
                        /> */}
                        <AtomInput
                          icon={true}
                          label="Ano"
                          placeholder="Ano"
                          width="100%"
                          value={values.ano}
                          onBlur={handleBlur("ano")}
                          disabled
                          onChange={handleChange("ano")}
                        />
                        <AtomInput
                          icon={true}
                          label="Câmbio"
                          placeholder="Câmbio"
                          width="100%"
                          value={values.cambio}
                          onBlur={handleBlur("cambio")}
                          disabled
                          onChange={handleChange("cambio")}
                        />
                        <AtomInput
                          icon={true}
                          label="Final da placa"
                          placeholder="Final da placa"
                          width="100%"
                          value={values.finalPlaca}
                          onBlur={handleBlur("finalPlaca")}
                          disabled
                          onChange={handleChange("finalPlaca")}
                        />
                        <AtomInput
                          icon={true}
                          label="Cor"
                          placeholder="Cor"
                          width="100%"
                          value={values.cor}
                          onBlur={handleBlur("cor")}
                          onChange={handleChange("cor")}
                          disabled
                        />
                      </div>

                      <div className="col d-flex flex-column text-start">
                        {/* <AtomInput
                          icon={true}
                          label="Modelo"
                          placeholder="Modelo"
                          width="100%"
                          value={values.modelo}
                          onBlur={handleBlur("modelo")}
                          onChange={handleChange("modelo")}
                          disabled
                        /> */}
                        <AtomInput
                          icon={true}
                          label="Valor"
                          placeholder="Valor"
                          width="100%"
                          value={values.valor}
                          onBlur={handleBlur("valor")}
                          onChange={handleChange("valor")}
                          disabled
                        />
                        <AtomInput
                          icon={true}
                          label="KM"
                          placeholder="KM"
                          width="100%"
                          value={values.km}
                          onBlur={handleBlur("km")}
                          onChange={handleChange("km")}
                          disabled
                        />
                        <AtomInput
                          icon={true}
                          label="Combustível"
                          placeholder="Combustível"
                          width="100%"
                          value={values.combustivel}
                          onBlur={handleBlur("combustivel")}
                          onChange={handleChange("combustivel")}
                          disabled
                        />
                      </div>

                    </div>

                    <div className="row">

                      <AtomInput
                        icon={true}
                        label="Sobre este veículo"
                        placeholder="Sobre este veículo"
                        width="100%"
                        value={values.sobreVeiculo}
                        onBlur={handleBlur("sobreVeiculo")}
                        disabled
                        onChange={handleChange("sobreVeiculo")}
                        variant="outlined"
                        multiline={true}
                        rows={5}
                      />

                    </div>
                    <div className="row">

                      <AtomInput
                        icon={true}
                        label="Sobre este veículo"
                        placeholder="Sobre este veículo"
                        width="100%"
                        value={values.itens}
                        onBlur={handleBlur("itens")}
                        disabled
                        onChange={handleChange("itens")}
                        variant="outlined"
                        multiline={true}
                        rows={rowSelected.Opcionais.length}
                      />

                    </div>

                    {renderTitleBloco("Fotos do veículo")}
                    <div className="row">
                      {
                        rowSelected.Fotos.split(",").map((f: any, i: number) => {
                          return (
                            <div key={i} className="col-4">
                              <div 
                                style={{ 
                                  backgroundImage: `url(${f})`,
                                  backgroundPosition: 'center',
                                  backgroundRepeat: 'no-repeat',
                                  backgroundSize: 'contain',
                                  cursor: 'pointer',
                                  height: '300px',
                                  marginBottom: '20px',
                                  width: '100%'
                                }}
                                onClick={() => openImage(f)}
                              >
                              </div>
                            </div>
                          )
                        })
                      }
                    </div>

                    {renderTitleBloco("Laudo veicular")}
                    <div className="row">
                      <div onDragOver={onDragOver} onDrop={(event) => onDrop(event)} className="col-12" style={{ border: "1px solid #989898", padding: "10px 24px", borderRadius: "5px" }}>
                        <p style={{ fontSize: "12px", color: "#989898", display: "inline-block", float: "left", marginTop: "-21px", background: "white", marginLeft: "-17px", padding: "0px 5px 0px 5px" }}>Anexo</p>
                        <input
                          accept="application/pdf"
                          style={{ display: 'none' }}
                          id="laudo-file"
                          type="file"
                          onChange={fileChange}
                        />
                        <label style={{ cursor: "pointer", color: "rgb(60, 60, 59)", background: "rgb(188, 188, 188)", width: "100%", fontSize: "16px", paddingTop: "11px", height: "45px" }} htmlFor="laudo-file">
                          Solte o arquivo PDF para fazer upload ou clique aqui para selecionar
                        </label>

                        {
                          nameFileSelected ?
                            <p style={{ fontSize: "16px", width: "100%", textAlign: "left", marginTop: "23px", color: "/* #3C3C3B */" }}>
                              <FontAwesomeIcon onClick={clearFile} style={{ color: "#C72424", marginRight: 11, fontSize: 11, cursor: "pointer" }} icon={faMinusCircle} />

                              {nameFileSelected}
                            </p>
                            :
                            rowSelected.urlAnexo &&
                            <p onClick={() => downloadLaudo()} style={{ cursor: "pointer", fontSize: "16px", width: "100%", textAlign: "left", marginTop: "23px", color: "/* #3C3C3B */" }}>
                              Laudo veicular.pdf
                          </p>
                        }
                      </div>
                    </div>

                    <div className="row">
                      <div className="d-flex">
                        <Button
                          disabled={!Boolean(nameFileSelected)}
                          onClick={() => {
                            submitForm();
                          }}
                          style={{
                            borderRadius: 5,
                            marginTop: 30,
                            backgroundColor: "#3C3C3B",
                            color: "white",
                            padding: "12px 0px",
                            height: "35px",
                            width: "150px",
                          }}
                        >
                          <h5
                            style={{
                              margin: 0,
                              fontWeight: "bold",
                              fontSize: "16px",
                              lineHeight: 0
                            }}
                          >
                            Salvar
                            </h5>
                        </Button>
                        <Button
                          disabled={isSubmitting}
                          onClick={() => {
                            navigate(`/stock`)
                            setEditting("");
                            setStockForm(SCHEMA_INITIAL_VALUES);
                            window.scrollTo(0, 0)
                          }}
                          style={{
                            borderRadius: 5,
                            borderColor: "transparent",
                            marginTop: 30,
                            backgroundColor: "transparent",
                            color: "black",
                            padding: "12px 0px",
                            height: "35px",
                            width: "150px",
                          }}
                        >
                          <h5
                            style={{
                              margin: 0,
                              fontWeight: "bold",
                              fontSize: "16px",
                              lineHeight: 0
                            }}
                          >
                            Cancelar
                        </h5>
                        </Button>
                      </div>
                    </div>

                  </>
                )}
              </Formik>
            </div>

          </div>
          <br />
        </>

      ) : (
          <>
            <div
              style={{ padding: "0px 42px", marginBottom: "30px" }}
              className="w-100  row "
            >
              <div className="col text-start d-flex align-items-center">
                <h4
                  style={{ fontSize: 18, color: "#3C3C3B", fontWeight: "bold" }}
                  className="text-uppercase"
                >
                  {`ESTOQUE`}
                </h4>
              </div>
            </div>
            <div style={{ padding: "0px 42px" }} className="w-100 row ">
              <BootstrapTable
                keyField="id"
                data={stockList}
                bordered={false}
                striped={true}
                hover={true}
                columns={columns}
                rowEvents={rowEvents}
                filter={filterFactory()}
              />
            </div>
          </>
        )}
    </>
  );
};

export default Stock;
