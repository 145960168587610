import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { RootState, AppThunk } from "../../app/store";
import ToastMessage from "../../components/Toasts";
import { Login, Retrive } from "./userApi";

export interface UserState {
  nome: string;
  tokenAuth: string;
  id: number;
  email: string;
  phone: string;
  status: "idle" | "loading" | "failed";
}

export interface UserLogin {
  email: string;
  password: string;
}

export interface AdmNew {
  nome: string;
  email: string;
  status: boolean;
}

export interface FaqNew {
  pergunta: string;
  resposta: string;
}

export interface UserNew {
  nome: string;
  email: string;
  status: boolean;
}

export interface NewPassword {
  newPassword: string;
  confirmNewPassword: string;
  token: string;
}
const initialState: UserState = {
  nome: "",
  tokenAuth: "",
  id: -1,
  email: "",
  phone: "",
  status: "idle",
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const LoginThunk = createAsyncThunk(
  "user/login",
  async ({ password, email }: UserLogin) => {
    try {
      console.log("SIM");
      const response = await Login({ email, password });
      return response;
    } catch {
      toast.error(
        <ToastMessage title="Erro" msg="Verifique seu e-mail ou senha." />,
        {
          position: "bottom-center",
          autoClose: 500000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );

      return initialState;
    }
  }
);

export const userSlice = createSlice({
  name: "user",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    increment: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.id += 1;
    },
    decrement: (state) => {
      state.id -= 1;
    },
    // Use the PayloadAction type to declare the contents of `action.payload`
    setUser: (state, action: PayloadAction<UserState>) => {
      console.log(action.payload);
      state.email = action.payload.email;
      state.nome = action.payload.nome;
      state.tokenAuth = action.payload.tokenAuth;
      state.id = action.payload.id;
    },
    logoff: (state, action: PayloadAction<string>) => {
      state.email = initialState.email;
      state.nome = initialState.nome;
      state.tokenAuth = initialState.tokenAuth;
      state.id = initialState.id;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(LoginThunk.pending, (state) => {
        state.status = "loading";
      })
      .addCase(LoginThunk.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(LoginThunk.fulfilled, (state, action) => {
        state.email = action.payload.email;
        state.nome = action.payload.nome;
        state.tokenAuth = action.payload.tokenAuth;
        state.id = action.payload.id;
      });
  },
});

export const { increment, decrement, setUser, logoff } = userSlice.actions;

export const selectUser = (state: RootState) => state.user;

export const selectIsAuthenticated = (state: RootState) => {
  return state.user.tokenAuth.length > 0 ? true : false;
};

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.

export default userSlice.reducer;
