import ServiceApi from "../../api/index";

async function LoadPurchaseProposal() {
  const response = await ServiceApi.get(`all/oferta/compra`);
  return response.data;
}

async function Historico(dataInicial : string, dataFinal : string) {
  const response = await ServiceApi.get(`all/oferta/compra/historico/${dataInicial}/${dataFinal}`);
  return response.data;
} 
async function StatusCompra() {
  const response = await ServiceApi.get(`status-compra`);
  return response.data;
}

async function UpdateCompra(id : string, payload : any) {
  const response = await ServiceApi.put(`oferta/compra/update/${id}`, { ...payload },);
  return response.data;
}



export { LoadPurchaseProposal , StatusCompra, Historico, UpdateCompra};
