import ServiceApi from "../../api/index";
import { NewPassword, UserLogin, AdmNew, UserState } from "./userSlice";

async function CreateAdmin(payload: AdmNew) {
  const response = await ServiceApi.post(
    `auth/new-admin`,
    { ...payload },
    {
      headers: {
        "content-type": "application/json",
      },
    }
  );
  return response.data;
}
async function LoadAdmins() {
  const response = await ServiceApi.get(`admin`);
  return response.data;
}
async function DeleteAdmin(id: string, token: string) {
  const response = await ServiceApi.delete(`/user/force-delete/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
      "content-type": "application/json",
      accept: "*/*",
      Connection: "keep-alive",
    },
  });
  return response.data;
}

async function UpdateAdmin(payload: any, id: string, token: string) {
  const response = await ServiceApi.put(
    `/user/${id}`,
    { ...payload },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "content-type": "application/json",
        accept: "*/*",
        Connection: "keep-alive",
      },
    }
  );
  return response.data;
}

// <ToastContainer
// position="bottom-center"
// autoClose={5000}
// hideProgressBar
// newestOnTop={false}
// closeOnClick
// rtl={false}
// pauseOnFocusLoss
// draggable
// pauseOnHover={false}
// />
export { CreateAdmin, LoadAdmins, UpdateAdmin, DeleteAdmin };
