import React, { useState, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import { createTheme } from "@mui/material";
import { Formik } from "formik";
import * as Yup from "yup";
import { AtomInput } from "../utils/ui/input/AtomInput";
import { useAppSelector } from "../app/hooks";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, {
  textFilter,
} from "react-bootstrap-table2-filter";
import {
  CreateFaq,
  DeleteFaq,
  LoadFaq,
  UpdateFaq,
} from "../features/user/faqApi";
import { blueGrey } from "@mui/material/colors";
import { selectUser } from "../features/user/userSlice";
import { toast } from "react-toastify";
import ToastMessage from "./Toasts";
import { AtomTextInput } from "../utils/ui/input/AtomTextInput";

const Faq = () => {
  const user = useAppSelector(selectUser);
  const SCHEMA_ADM_INITIAL_VALUES = {
    pergunta: "",
    resposta: "",
  };
  const [faq, setFaq] = useState([]);
  const loadFaqs = async () => {
    try {
      const resp = await LoadFaq();
      console.log("resp.data", resp.data)
      const adms = resp.data.map((adm: any) => {
        return { ...adm };
      });
      console.log('adms', adms)
      console.log("faq", faq)
      setFaq(adms);
    } catch {
      throw new Error("error");
    }
  };
  const excluiFaq = async () => {
    try {
      const resp = await DeleteFaq(isEditting, user.tokenAuth);
      await loadFaqs();
      setAdding(false);
      setEditting("");
      setadmform(SCHEMA_ADM_INITIAL_VALUES);
      setShow(false);
      toast.success(
        <ToastMessage
          title="Sucesso"
          msg={"O faq foi excluído com sucesso."}
        />,
        {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
    } catch {
      throw new Error("error");
    }
  };
  const novoFaq = async (values: any) => {
    try {
      const resp = await CreateFaq({ ...values });
      if (resp.error) {
        let errorStr = "";
        if (resp.error[0].message.includes("pergunta")) {
          errorStr = "Pergunta já cadastrado.";
        }
        if (resp.error[0].message.includes("resposta")) {
          errorStr = "Resposta já cadastrado.";
        }
        toast.error(<ToastMessage title="Erro" msg={errorStr} />, {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        throw new Error("error");
      } else {
        await loadFaqs();
        setAdding(false);
        setEditting("");
        setadmform(SCHEMA_ADM_INITIAL_VALUES);
        toast.success(
          <ToastMessage
            title="Sucesso"
            msg={"O faq foi cadastrado com sucesso."}
          />,
          {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      }
    } catch {
      console.log("");
    }
  };
  const updateFaq = async (values: any) => {
    try {
      console.log(isEditting);
      const resp = await UpdateFaq({ ...values }, isEditting, user.tokenAuth);
      if (resp.error) {
        let errorStr = "";
        if (resp.error[0].message.includes("pergunta")) {
          errorStr = "Pergunta já cadastrado.";
        }
        if (resp.error[0].message.includes("resposta")) {
          errorStr = "Resposta já cadastrado.";
        }
        toast.error(<ToastMessage title="Erro" msg={errorStr} />, {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        throw new Error("error");
      } else {
        await loadFaqs();
        setAdding(false);
        setEditting("");
        setadmform(SCHEMA_ADM_INITIAL_VALUES);
        toast.success(
          <ToastMessage
            title="Sucesso"
            msg={"O faq foi editado com sucesso."}
          />,
          {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      }
    } catch {
      console.log("");
    }
  };

  useEffect(() => {
    loadFaqs();
  }, []);
  const [age, setAge] = React.useState("");
  const theme = createTheme({
    palette: {
      primary: blueGrey,
    },
  });
  function priceFormatter(
    column: any,
    colIndex: any,
    { sortElement, filterElement }: any
  ): any {
    return (
      <div
        style={{
          color: "#3C3C3B",
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
          fontSize: "16px",
          fontWeight: "bold",
          marginLeft: 20,
          width: "100%",
        }}
      >
        {sortElement}
        {column.text}
        {filterElement}
      </div>
    );
  }
  /* CAMPOS DE PESQUISA E TABELA */
  const columns = [
    {
      dataField: "pergunta",
      headerStyle: {
        backgroundColor: "#989898",
        border: "none",
        borderRadius: "5px 0 0 0",
      },
      text: "Pesquisar",
      filter: textFilter({
        placeholder: "Pesquisar",
        style: { width: '110%', height: 30, marginTop: 5, marginBottom: 5 },
      }),
      headerFormatter: priceFormatter,
      style: {
        width: "100%",
        cursor: "pointer",
        border: "none",
        "text-align": "start",
        paddingLeft: 30,
      },
    },
  ];

  const SCHEMA_ADM = Yup.object().shape({
    pergunta: Yup.string().required("Campo obrigatório"),
    resposta: Yup.string().required("Campo obrigatório"),
  });
  const [admform, setadmform] = useState(SCHEMA_ADM_INITIAL_VALUES);
  const [isAdding, setAdding] = useState(false);
  const [statenow, setStatenow] = useState(false);
  const [isEditting, setEditting] = useState("");
  const rowEvents = {
    onClick: (e: any, row: any, rowIndex: any) => {
      console.log(row);
      setEditting(row.id);
      setadmform({ pergunta: row.pergunta, resposta: row.resposta });
    },
  };
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <Modal centered show={show} onHide={handleClose}>
        <Modal.Body>
          <div className="row w-100 d-flex  flex-column">
            Deseja excluir esta pergunta frequente?
            <div className="d-flex  my-3 flex-row ">
              <Button
                style={{
                  width: "100%",
                  marginLeft: "20px",
                  marginRight: "10px",
                  backgroundColor: "#2CA44E",
                  borderColor: "transparent",
                }}
                variant="secondary"
                onClick={() => excluiFaq()}
              >
                Sim
              </Button>

              <Button
                style={{
                  borderColor: "transparent",
                  width: "100%",
                  backgroundColor: "#D32F2F",
                }}
                variant="primary"
                onClick={handleClose}
              >
                Não
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {isAdding || isEditting ? (
        <>
          <div className="w-100 row" style={{ padding: "0px 42px" }}>
            <div className="col text-start d-flex align-items-center">
              <h4
                style={{ fontSize: 18, color: "#3C3C3B", fontWeight: "bold" }}
                className="text-uppercase"
              >
                {`Faq  >  ${isAdding ? "Nova" : "Editar"
                  } Pergunta`}
              </h4>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              width: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: 30,
                backgroundColor: "#bcbcbc",
                height: "32px",
              }}
            >
              <span
                style={{
                  padding: "0 10px 0 40px",
                  fontWeight: "bold",
                  fontSize: 16,
                  color: "#3C3C3B",
                }}
              >
                Pergunta Frequente
              </span>
            </div>
          </div>
          <br />
          <div style={{ padding: "0px 42px" }} className="w-100 row">
            <Formik
              initialValues={admform}
              validationSchema={SCHEMA_ADM}
              onSubmit={async (values, { setSubmitting }) => {
                try {
                  const newvalues = {
                    ...values,
                  };
                  console.log(newvalues);
                  if (isAdding) {
                    novoFaq(newvalues);
                    console.log('novoFaq', newvalues)
                  } else {
                    updateFaq(newvalues);
                    console.log('updateFaq', newvalues)
                  }
                } catch (e) {
                  setSubmitting(false);
                  console.log('setSubmitting', false)
                }
              }}
            >
              {({
                errors,
                handleChange,
                submitForm,
                touched,
                isSubmitting,
                handleBlur,
                values,
              }) => (
                <div className="row">
                  <div className="col d-flex flex-column text-start">
                    <AtomInput
                      icon={true}
                      label="Pergunta *"
                      placeholder="Pergunta *"
                      width="100%"
                      onChange={handleChange("pergunta")}
                      value={values.pergunta}
                      errors={errors.pergunta}
                      touched={touched.pergunta}
                      onBlur={handleBlur("pergunta")}
                    />
                    <br />
                    <AtomTextInput
                      icon={true}
                      multiline={true}
                      rows={8}
                      label="Resposta *"
                      placeholder="Resposta *"
                      width="100%"
                      onChange={handleChange("resposta")}
                      value={values.resposta}
                      errors={errors.resposta}
                      touched={touched.resposta}
                      onBlur={handleBlur("resposta")}
                      maxLength={2000}
                    />
                    <div className="d-flex">
                      <Button
                        disabled={isSubmitting}
                        onClick={() => {
                          submitForm();
                        }}
                        style={{
                          borderRadius: 5,
                          marginTop: 30,
                          backgroundColor: "#3C3C3B",
                          color: "white",
                          padding: "12px 0px",
                          height: "35px",
                          width: "150px",
                        }}
                      >
                        <h5
                          style={{
                            margin: 0,
                            fontWeight: "bold",
                            fontSize: "16px",
                            lineHeight: 0
                          }}
                        >
                          Salvar
                        </h5>
                      </Button>
                      {isEditting && (
                        <Button
                          className="mx-3"
                          disabled={isSubmitting}
                          onClick={() => {
                            setShow(true);
                          }}
                          style={{
                            borderRadius: 5,
                            borderColor: "transparent",
                            marginTop: 30,
                            backgroundColor: "#D32F2F",
                            color: "white",
                            padding: "12px 0px",
                            height: "35px",
                            width: "150px",
                          }}
                        >
                          <h5
                            style={{
                              margin: 0,
                              fontWeight: "bold",
                              fontSize: "16px",
                              lineHeight: 0
                            }}
                          >
                            Excluir
                          </h5>
                        </Button>
                      )}
                      <Button
                        disabled={isSubmitting}
                        onClick={() => {
                          setAdding(false);
                          setEditting("");
                          setadmform(SCHEMA_ADM_INITIAL_VALUES);
                        }}
                        style={{
                          borderRadius: 5,
                          borderColor: "transparent",
                          marginTop: 30,
                          backgroundColor: "transparent",
                          color: "black",
                          padding: "12px 0px",
                          height: "35px",
                          width: "150px",
                        }}
                      >
                        <h5
                          style={{
                            margin: 0,
                            fontWeight: "bold",
                            fontSize: "16px",
                            lineHeight: 0
                          }}
                        >
                          Cancelar
                        </h5>
                      </Button>

                    </div>
                  </div>
                </div>
              )}
            </Formik>
          </div>
        </>
      ) : (
        <>
          <div
            style={{ padding: "0px 42px", marginBottom: "30px" }}
            className="w-100  row "
          >
            <div className="col text-start d-flex align-items-center">
              <h4
                style={{ fontSize: 18, color: "#3C3C3B", fontWeight: "bold" }}
                className="text-uppercase"
              >
                {"Faq"}
              </h4>
            </div>
            <div className="col justify-content-end d-flex  align-items-center text-decoration-underline ">
              <h3
                onClick={() => {
                  setAdding(true);
                }}
                style={{
                  fontSize: 16,
                  cursor: "pointer",
                  color: "#3C3C3B",
                  fontWeight: "bold",
                }}
              >
                + Nova Pergunta
              </h3>
            </div>
          </div>

          <div style={{ padding: "0px 42px" }} className="w-100 row ">
            <BootstrapTable
              keyField="id"
              data={faq}
              bordered={false}
              striped={true}
              hover={true}
              columns={columns}
              rowEvents={rowEvents}
              filter={filterFactory()}
            />
          </div>
        </>
      )}
    </>
  );
};

export default Faq;
