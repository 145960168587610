import './PrivateRoute.css';
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import AccessDenied from "../components/AccessDenied";
import { Roles } from "./Roles";
import { selectUser, selectIsAuthenticated } from "../features/user/userSlice";
import Menu from "../components/Menu";
import LogoPositivapng from "../assets/images/LogoPositiva.svg";
interface Props {
  component: React.ComponentType;
  path?: string;
  roles: Array<Roles>;
}

export const PrivateRoute: React.FC<Props> = ({
  component: RouteComponent,
  roles,
}) => {
  const user = useSelector(selectUser);
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const userHasRequiredRole = user ? true : false;

  if (isAuthenticated && userHasRequiredRole) {
    return (
      <>
        <div style={{ maxWidth: "100.62%", marginLeft: 0, marginRight: 0 }} className="row h-100">
          <Menu />
          <div className="px-0 mx-0 h-100 col-md d-flex flex-column w-100">
            <img
              className="mt-3"
              style={{ width: "129px", height: "28px", marginLeft: '240px' }}
              src={LogoPositivapng}
              alt="Logo positiva"
            />
            <div
              className='overflow'
              style={{
                padding: "40px 0px  0px  0px",
                display: "flex",
                borderRadius: "52px 0 0 0",
                flexDirection: "column",
                alignItems: "center",
                // justifyContent: "center",
                marginTop: 20,
                marginLeft: '200px',
                width: "calc(100% - 200px)",
                height: "800%",
                backgroundColor: "white",
                background:
                  'url("https://imagenspublic.s3.sa-east-1.amazonaws.com/yellow_car_transparent.png") white bottom 20px right -200px no-repeat',
              }}
            >
              <RouteComponent />
            </div>
          </div>
        </div>
      </>
    );
  }

  if (isAuthenticated && !userHasRequiredRole) {
    return <AccessDenied />;
  }

  return <Navigate to="/" />;
};
