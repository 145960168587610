import React, { useState, useEffect } from "react";
import Logo from "../assets/images/logoInvertida.svg";
import { Button, Modal } from "react-bootstrap";
import Carro from "../assets/images/yellow_car.png";
import { useDispatch } from "react-redux";
import { createTheme, TextField, ThemeProvider } from "@mui/material";
import { Formik } from "formik";
import BTable from "react-bootstrap/Table";
import * as Yup from "yup";
import { AtomInput } from "../utils/ui/input/AtomInput";
import { useAppSelector } from "../app/hooks";
import { useNavigate } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import filterFactory, {
  textFilter,
  Comparator,
} from "react-bootstrap-table2-filter";
import {
  CreateAdmin,
  DeleteAdmin,
  LoadAdmins,
  UpdateAdmin,
} from "../features/user/adminApi";
import { LoadPurchaseProposal, StatusCompra, Historico, UpdateCompra } from "../features/proposal/purchaseProposalApi";
import { blueGrey } from "@mui/material/colors";
import { selectUser } from "../features/user/userSlice";
import { toast } from "react-toastify";
import ToastMessage from "./Toasts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInfoCircle, faLongArrowAltUp
} from "@fortawesome/free-solid-svg-icons";

import { styled } from '@mui/material/styles';
import { AtomSelect } from "../utils/ui/input/AtomSelect";

const CssTextField = styled(TextField)({

  '& .error': {
    color: 'red',
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'red',
      }
    },
  },
});

const PurchaseProposal = () => {
  const navigate = useNavigate();

  const user = useAppSelector(selectUser);
  const SCHEMA_INITIAL_VALUES = {
    name: "",
    veiculo: "",
    status: "",
    data: "",
    cpf: "",
    email: "",
    phone: "",
    isCarChange: "",
    model: "",
    marca: "",
    year: "",
    km: "",
    priceFipe: "",
    priceWantedCar: "",
    priceRemaingCar: "",
    price: "",
    priceTotal: "",
    formPayment: ""
  };
  const SCHEMA_ADM = Yup.object().shape({
    status: Yup.string().required("Campo obrigatório"),
  });
  const SCHEMA_HISTORY = Yup.object().shape({
    year: Yup.string().required("Campo obrigatório"),
    month: Yup.string().required("Campo obrigatório"),
  });
  const SCHEMA_HISTORY_INITIAL = {
    year: "",
    month: ""
  }


  const [propostasCompra, setPropostasCompra] = useState([]);
  const [listStatus, setListStatus] = useState([]);
  const [propostaForm, setPropostaForm] = useState(SCHEMA_INITIAL_VALUES);
  const [historyForm, setHistoryForm] = useState(SCHEMA_HISTORY_INITIAL)
  const [viewHistory, setViewHistory] = useState(false);
  const [statenow, setStatenow] = useState(false);
  const [isEditting, setEditting] = useState("");
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [isSearch, setIsSearch] = useState(false);
  const [age, setAge] = React.useState("");
  const [rowSelected, setRowSelected] = useState<any>({});

  const dataAtual = new Date();
  const listYears = [dataAtual.getFullYear(), dataAtual.getFullYear() - 1, dataAtual.getFullYear() - 2];
  const listMonths = ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"];
  const columns = [
    {
      dataField: "User.name",
      headerStyle: {
        backgroundColor: "#989898",
        border: "none",
        borderRadius: "5px 0 0 0",
      },
      text: "Nome",
      filter: textFilter({
        placeholder: "Pesquisar",
        style: { height: 30, marginTop: 5, marginBottom: 5 },
      }),
      headerFormatter: priceFormatter,
      style: {
        width: "100%",
        cursor: "pointer",
        border: "none",
        "text-align": "start",
        paddingLeft: 30,
      },
      sort: true
    },
    {
      dataField: "veiculo",
      text: "Veículo",
      headerStyle: {
        backgroundColor: "#989898",
        borderColor: "#989898",
        borderRadius: "0 0 0 0",
      },
      filter: textFilter({
        placeholder: "Pesquisar",
        style: { height: 30, marginTop: 5, marginBottom: 5 },
      }),
      headerFormatter: priceFormatter,
      style: {
        cursor: "pointer",
        border: "none",
        "text-align": "start",
        paddingLeft: 30,
      },
      sort: true
    },
    {
      dataField: "valueFormated",
      text: "Valor",
      headerStyle: {
        backgroundColor: "#989898",
        borderColor: "#989898",
        borderRadius: "0 5px 0 0",
      },
      filter: textFilter({
        placeholder: "Pesquisar",
        style: { height: 30, marginTop: 5, marginBottom: 5 },
      }),
      headerFormatter: priceFormatter,
      style: {
        cursor: "pointer",
        border: "none",
        "text-align": "start",
        paddingLeft: 30,
      },
      sort: true
    },
  ];

  useEffect(() => {
    setViewHistory(false);
    setHistoryForm(SCHEMA_HISTORY_INITIAL);
    setIsSearch(false);
    loadPurchaseProposal();
    getStatusCompra();
  }, []);

  const loadPurchaseProposal = async () => {
    try {
      const resp = await LoadPurchaseProposal();

      resp.data.forEach((element: any) => {
        element.veiculo = element.Veiculo.Marca.nome + " " + element.Veiculo.Modelo.nome + " " + element.Veiculo.Versao.nome;
        element.valueFormated = formatValuePrice(Number(element.Veiculo.Preco));
      });

      setPropostasCompra(resp.data);
    } catch {
      console.log("error");
    }
  };

  const getStatusCompra = async () => {
    try {
      const resp = await StatusCompra();
      setListStatus(resp.data);
    } catch {
      console.log("error");
    }
  };


  const theme = createTheme({
    palette: {
      primary: blueGrey,
    },
  });

  function priceFormatter(
    column: any,
    colIndex: any,
    { sortElement, filterElement }: any
  ): any {
    return (
      <div
        style={{
          color: "#3C3C3B",
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
          fontSize: "16px",
          fontWeight: "bold",
          marginLeft: 20,
          width: "100%",
        }}
      >
        {sortElement}
        {column.text}
        {filterElement}
      </div>
    );
  }

  const formatValuePrice = (value: number) => {
    return `R$ ${value.toLocaleString('pt-br', { minimumFractionDigits: 2 })}`
  }

  const rowEvents = {
    onClick: (e: any, row: any, rowIndex: any) => {
      setRowSelected(row);
      setEditting(row.id);
      setPropostaForm({
        name: row.User.name,
        status: row.StatusCompra.id,
        data: (new Date(row.createdAt)).toLocaleDateString("pt-br"),
        veiculo: row.Veiculo?.Marca.nome + " " + row.Veiculo?.Modelo.nome + " " + row.Veiculo?.Versao.nome,
        cpf: row.User.cpf,
        email: row.User.email,
        phone: row.User.phone,
        isCarChange: row.temVeiculo ? "Sim" : "Não",
        model: row.modelo,
        marca: row.marca,
        year: row.anoFabricacao,
        km: Number(row.km).toLocaleString('pt-br'),
        priceFipe: formatValuePrice(Number(row.precoTotal)),
        priceWantedCar: formatValuePrice(Number(row.valorDesejado)),
        priceRemaingCar: formatValuePrice(Number(row.valorRestante)),
        price: formatValuePrice(Number(row.Veiculo.Preco)),
        priceTotal: row.temVeiculo ? formatValuePrice(Number(row.valorDesejado) + Number(row.valorRestante)) : formatValuePrice(Number(row.valorDesejado)) ,
        formPayment: row.FormaPagamento.descricao
      });
      window.scrollTo(0, 0)
    },
  };

  const formatDateEn = (date: Date) => {
    const day = String(date.getDate()).length > 1 ? date.getDate() : "0" + date.getDate()
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).length > 1 ? (date.getMonth() + 1) : "0" + (date.getMonth() + 1);

    return `${year}-${month}-${day}`

  }

  const updateCompra = async (values: any) => {

    let body = rowSelected;
    body.statusId = values.status;

    delete body.User;
    delete body.FormaPagamento;
    delete body.StatusCompra;
    delete body.veiculo;
    delete body.valueFormated;

    try {
      const resp = await UpdateCompra(body.id, body);
      setEditting("");
      setPropostaForm(SCHEMA_INITIAL_VALUES);
      loadPurchaseProposal();
      toast.success(
        <ToastMessage
          title="Sucesso"
          msg={"Proposta atualizada com sucesso"}
        />,
        {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
    } catch (error) {

    }

    console.log(body);
  }

  const searchHistory = async (values: any) => {
    const indiceMes = listMonths.indexOf(values.month)
    const dataInicio = new Date(`${values.year}/${indiceMes + 1}`);
    let dataFim = new Date(`${values.year}-${indiceMes + 2}`);
    const mili = dataFim.setDate(dataFim.getDate() - 1)
    dataFim = new Date(mili)
    try {
      const resp = await Historico(formatDateEn(dataInicio), formatDateEn(dataFim));
      resp.data.forEach((element: any) => {
        element.veiculo = element.Veiculo.Marca.nome + " " + element.Veiculo.Modelo.nome + " " + element.Veiculo.Versao.nome;
        element.valueFormated = formatValuePrice(Number(element.Veiculo.Preco));
      });

      setPropostasCompra(resp.data);
      setIsSearch(true)
    } catch {
      console.log("error");
      setPropostasCompra([]);
      setIsSearch(true)
    }
  }

  const renderTitleBloco = (value: string) => {
    return (
      <>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            marginLeft: "-42px",
            width: "100%",
            marginBottom: "13px"
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: 30,
              backgroundColor: "#bcbcbc",
              height: "32px",
            }}
          >
            <span
              style={{
                padding: "0 30px 0 42px",
                fontWeight: "bold",
                fontSize: 16,
                color: "#3C3C3B",
              }}
            >
              {value}
            </span>
          </div>
        </div>
      </>

    )
  }

  return (
    <>
      {isEditting ? (
        <>
          <div className="w-100 row" style={{ padding: "0px 42px" }}>
            <div className="col text-start d-flex align-items-center">
              <h4
                style={{ fontSize: 18, color: "#3C3C3B", fontWeight: "bold" }}
                className="text-uppercase"
              >
                {`${viewHistory ? 'PROPOSTAS DE COMPRA > HISTÓRICO > DETALHES DA PROPOSTA' : 'PROPOSTAS DE COMPRA > CONSULTAR'}`}
              </h4>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",

              width: "100%",
            }}
          >


            <div style={{ padding: "0px 42px" }} className="w-100 row">
              <Formik
                initialValues={propostaForm}
                validationSchema={SCHEMA_ADM}
                onSubmit={async (values, { setSubmitting }) => {
                  updateCompra(values)
                  window.scrollTo(0, 0)
                }}
              >
                {({
                  errors,
                  handleChange,
                  submitForm,
                  touched,
                  isSubmitting,
                  handleBlur,
                  values,
                }) => (
                  <>

                    {renderTitleBloco("Dados da proposta")}
                    <div className="row">
                      <div className="col d-flex flex-column text-start">
                        <AtomInput
                          icon={true}
                          label="Data"
                          placeholder="Data"
                          width="100%"
                          onChange={handleChange("name")}
                          value={values.data}
                          onBlur={handleBlur("data")}
                          disabled
                        />
                        <AtomInput
                          icon={true}
                          label="Veículo"
                          placeholder="Veículo"
                          width="100%"
                          onChange={handleChange("veiculo")}
                          value={values.veiculo}
                          onBlur={handleBlur("veiculo")}
                          disabled
                        />
                      </div>
                      <div className="col d-flex flex-column text-start">
                        <ThemeProvider theme={theme}>
                          <TextField
                            style={{ width: "100%", textAlign: "start" }}
                            variant="standard"
                            name="status"
                            id="status"
                            select
                            onBlur={handleBlur("status")}
                            label={`Status ${!viewHistory ? '*' : ''}`}
                            value={values.status}
                            onChange={handleChange("status")}
                            disabled={viewHistory}
                          >
                            {
                              listStatus.map((s: any) => {
                                return (
                                  <MenuItem key={1} value={s.id}>
                                    {s.name}
                                  </MenuItem>
                                )
                              })
                            }
                          </TextField>
                          {errors.status && touched.status && (
                            <p style={{ color: "red" }}>{errors.status}</p>
                          )}
                          {(!errors.status || !touched.status) && (
                            <p style={{ color: "transparent" }}>_</p>
                          )}
                        </ThemeProvider>
                        {
                          !viewHistory &&
                          <div>
                            <span style={{ color: "#3C3C3B", display: "inline-block" }}>
                              <svg style={{
                                width: "18px",
                                marginRight: "5px",
                                marginTop: "-4px",
                              }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                <path d="M384 320c-17.67 0-32 14.33-32 32v96H64V160h96c17.67 0 32-14.32 32-32s-14.33-32-32-32L64 96c-35.35 0-64 28.65-64 64V448c0 35.34 28.65 64 64 64h288c35.35 0 64-28.66 64-64v-96C416 334.3 401.7 320 384 320zM488 0H352c-12.94 0-24.62 7.797-29.56 19.75c-4.969 11.97-2.219 25.72 6.938 34.88L370.8 96L169.4 297.4c-12.5 12.5-12.5 32.75 0 45.25C175.6 348.9 183.8 352 192 352s16.38-3.125 22.62-9.375L416 141.3l41.38 41.38c9.156 9.141 22.88 11.84 34.88 6.938C504.2 184.6 512 172.9 512 160V24C512 10.74 501.3 0 488 0z" />
                              </svg>
                              {/* <FontAwesomeIcon style={{ color: "#ECB800", marginRight: 15, fontSize: 19 }} icon={faLongArrowAltUp} /> */}
                            </span>
                            <span onClick={() => navigate(`/stock/${rowSelected.veiculoId}`)} style={{ display: "inline-block", marginTop: "32px", textAlign: "left", textDecoration: "underline", letterSpacing: "0px", color: "#3C3C3B", cursor: "pointer" }}>
                              Link para estoque
                          </span>
                          </div>
                        }

                      </div>
                    </div>

                    {renderTitleBloco("Dados do cliente")}
                    <div className="row">
                      <div className="col d-flex flex-column text-start">
                        <AtomInput
                          icon={true}
                          label="Nome"
                          placeholder="Nome"
                          width="100%"
                          value={values.name}
                          disabled
                          onChange={handleChange("name")}
                        />

                        <AtomInput
                          icon={true}
                          label="CPF"
                          placeholder="CPF"
                          width="100%"
                          onChange={handleChange("cpf")}
                          value={values.cpf}
                          disabled
                        />
                      </div>
                      <div className="col d-flex flex-column text-start">
                        <AtomInput
                          icon={true}
                          label="E-mail"
                          placeholder="E-mail"
                          width="100%"
                          onChange={handleChange("email")}
                          value={values.email}
                          disabled
                        />
                        <AtomInput
                          icon={true}
                          label="Celular"
                          placeholder="Celular"
                          width="100%"
                          onChange={handleChange("cellular")}
                          value={values.phone}
                          disabled
                        />
                      </div>
                    </div>

                    {renderTitleBloco("Veículo na troca")}
                    <div className="row">
                      <div className="col d-flex flex-column text-start">
                        <AtomInput
                          icon={true}
                          label="Possui veículo na troca?"
                          placeholder="Possui veículo na troca?"
                          width="100%"
                          value={values.isCarChange}
                          disabled
                          onChange={handleChange("isCarChange")}
                        />
                      </div>
                      <div className="col d-flex flex-column text-start">
                      </div>
                    </div>

                    {
                      values.isCarChange == "Sim" &&
                      <>
                        {renderTitleBloco("Dados do veículo")}
                        <div className="row">
                          <div className="col d-flex flex-column text-start">
                            <AtomInput
                              icon={true}
                              label="Veículo"
                              placeholder="Veículo"
                              width="100%"
                              value={values.marca + ' ' + values.model}
                              disabled
                              onChange={handleChange("model")}
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col d-flex flex-column text-start">
                            <AtomInput
                              icon={true}
                              label="Ano"
                              placeholder="Ano"
                              width="100%"
                              value={values.year}
                              disabled
                              onChange={handleChange("year")}
                            />
                          </div>
                          <div className="col d-flex flex-column text-start">
                            <AtomInput
                              icon={true}
                              label="Km"
                              placeholder="Km"
                              width="100%"
                              value={values.km}
                              disabled
                              onChange={handleChange("km")}
                            />
                          </div>
                        </div>
                      </>
                    }


                    {
                      values.isCarChange == "Sim" &&
                      <>
                        {renderTitleBloco("Tabela FIPE")}
                        <div className="row">
                          <div className="col d-flex flex-column text-start">
                            <AtomInput
                              icon={true}
                              label="Valor tabela FIPE"
                              placeholder="Valor tabela FIPE"
                              width="100%"
                              value={values.priceFipe}
                              disabled
                              onChange={handleChange("priceFipe")}
                            />
                          </div>
                          <div className="col d-flex flex-column text-start">
                          </div>
                        </div>
                      </>
                    }

                    {renderTitleBloco("Oferta")}

                    <div className="row">
                      <div className="col d-flex flex-column text-start">
                        {
                          values.isCarChange == "Sim" &&
                          <AtomInput
                            icon={true}
                            label="Valor desejado no veículo"
                            placeholder="Valor desejado no veículo"
                            width="100%"
                            value={values.priceWantedCar}
                            disabled
                            onChange={handleChange("priceWantedCar")}
                          />
                        }
                        <AtomInput
                          icon={true}
                          label="Valor original"
                          placeholder="Valor original"
                          width="100%"
                          value={values.price}
                          disabled
                          onChange={handleChange("price")}
                        />
                      </div>

                      <div className="col d-flex flex-column text-start">
                        {
                          values.isCarChange == "Sim" &&
                          <AtomInput
                            icon={true}
                            label="Valor restante para oferta"
                            placeholder="Valor restante para oferta"
                            width="100%"
                            value={values.priceRemaingCar}
                            disabled
                            onChange={handleChange("priceRemaingCar")}
                          />
                        }
                        <AtomInput
                          icon={true}
                          label={`${values.isCarChange == "Sim" ? 'Valor total da oferta' : 'Valor da oferta'}`}
                          placeholder={`${values.isCarChange == "Sim" ? 'Valor total da oferta' : 'Valor da oferta'}`}
                          width="100%"
                          value={values.priceTotal}
                          disabled
                          onChange={handleChange("priceTotal")}
                        />
                      </div>
                    </div>

                    {renderTitleBloco("Forma de pagamento")}
                    <div className="row">
                      <div className="col d-flex flex-column text-start">
                        <AtomInput
                          icon={true}
                          label="Forma de pagamento"
                          placeholder="Forma de pagamento"
                          width="100%"
                          value={values.formPayment}
                          disabled
                          onChange={handleChange("formPayment")}
                        />
                      </div>
                      <div className="col d-flex flex-column text-start">
                      </div>
                    </div>

                    <div className="row">
                      <div className="d-flex">
                        {
                          !viewHistory &&

                          <Button
                            disabled={isSubmitting}
                            onClick={() => {
                              submitForm();
                            }}
                            style={{
                              borderRadius: 5,
                              marginTop: 30,
                              backgroundColor: "#3C3C3B",
                              color: "white",
                              padding: "12px 0px",
                              height: "35px",
                              width: "150px",
                            }}
                          >
                            <h5
                              style={{
                                margin: 0,
                                fontWeight: "bold",
                                fontSize: "16px",
                                lineHeight: 0
                              }}
                            >
                              Salvar
                            </h5>
                          </Button>
                        }
                        <Button
                          disabled={isSubmitting}
                          onClick={() => {
                            setEditting("");
                            setPropostaForm(SCHEMA_INITIAL_VALUES);
                            window.scrollTo(0, 0)
                          }}
                          style={{
                            borderRadius: 5,
                            borderColor: "transparent",
                            marginTop: 30,
                            backgroundColor: "transparent",
                            color: "black",
                            padding: "12px 0px",
                            height: "35px",
                            width: "150px",
                          }}
                        >
                          <h5
                            style={{
                              margin: 0,
                              fontWeight: "bold",
                              fontSize: "16px",
                              lineHeight: 0
                            }}
                          >
                            Cancelar
                        </h5>
                        </Button>
                      </div>
                    </div>
                  </>
                )}
              </Formik>
            </div>

          </div>
          <br />
        </>
      ) : (
          <>
            <div
              style={{ padding: "0px 42px", marginBottom: "30px" }}
              className="w-100  row "
            >
              <div className="col text-start d-flex align-items-center">
                <h4
                  style={{ fontSize: 18, color: "#3C3C3B", fontWeight: "bold" }}
                  className="text-uppercase"
                >
                  {`PROPOSTAS DE COMPRA ${viewHistory ? '> HISTÓRICO' : ''}`}
                </h4>
              </div>
              {
                !viewHistory &&
                <div className="col justify-content-end d-flex  align-items-center text-decoration-underline ">
                  <h3
                    onClick={() => {
                      setViewHistory(true);
                    }}
                    style={{
                      fontSize: 16,
                      cursor: "pointer",
                      color: "#3C3C3B",
                      fontWeight: "bold",
                    }}
                  >
                    Ver histórico
              </h3>
                </div>
              }

            </div>

            {
              viewHistory &&
              <div style={{ padding: "0px 42px" }} className="w-100  row " >
                <Formik
                  initialValues={historyForm}
                  validationSchema={SCHEMA_HISTORY}
                  onSubmit={async (values, { setSubmitting }) => {
                    console.log("pesquisar");
                    searchHistory(values);
                  }}
                >
                  {({
                    errors,
                    handleChange,
                    submitForm,
                    touched,
                    isSubmitting,
                    handleBlur,
                    values,
                  }) => (
                    <>
                      <div className="col-3 d-flex flex-column text-start">
                        <ThemeProvider theme={theme}>
                          <AtomSelect
                            label="Ano *"
                            placeholder="Ano *"
                            width="100%"
                            onChange={handleChange("year")}
                            value={values.year}
                            errors={errors.year}
                            touched={touched.year}
                            onBlur={handleBlur("year")}
                          >
                            {
                              listYears.map((y: any, i: number) => {
                                return (
                                  <MenuItem key={i} value={y}>
                                    {y}
                                  </MenuItem>
                                )
                              })
                            }
                          </AtomSelect>
                        </ThemeProvider>
                      </div>
                      <div className="col-3 d-flex flex-column text-start">
                        <ThemeProvider theme={theme}>
                          <AtomSelect
                            label="Mês *"
                            placeholder="Mês *"
                            width="100%"
                            onChange={handleChange("month")}
                            value={values.month}
                            errors={errors.month}
                            touched={touched.month}
                            onBlur={handleBlur("month")}
                          >
                            {
                              listMonths.map((m: any, i: number) => {
                                return (
                                  <MenuItem key={i} value={m}>
                                    {m}
                                  </MenuItem>
                                )
                              })
                            }
                          </AtomSelect>
                        </ThemeProvider>
                      </div>
                      <div className="col-4 " style={{ marginTop: -25 }}>
                        <Button
                          disabled={isSubmitting}
                          onClick={() => {
                            submitForm();
                          }}
                          style={{
                            borderRadius: 5,
                            marginTop: 30,
                            backgroundColor: "#3C3C3B",
                            color: "white",
                            padding: "12px 0px",
                            height: "35px",
                            width: "150px",
                          }}
                        >
                          <h5
                            style={{
                              margin: 0,
                              fontWeight: "bold",
                              fontSize: "16px",
                              lineHeight: 0
                            }}
                          >
                            Pesquisar
                        </h5>
                        </Button>
                        <Button
                          disabled={isSubmitting}
                          onClick={() => {
                            setViewHistory(false);
                            setHistoryForm(SCHEMA_HISTORY_INITIAL);
                            setIsSearch(false);
                            loadPurchaseProposal();
                          }}
                          style={{
                            borderRadius: 5,
                            borderColor: "transparent",
                            marginTop: 30,
                            backgroundColor: "transparent",
                            color: "black",
                            padding: "12px 0px",
                            height: "35px",
                            width: "150px",
                          }}
                        >
                          <h5
                            style={{
                              margin: 0,
                              fontWeight: "bold",
                              fontSize: "16px",
                              lineHeight: 0
                            }}
                          >
                            Cancelar
                        </h5>
                        </Button>
                      </div>
                    </>
                  )}
                </Formik>

              </div>
            }
            {
              (!viewHistory || (viewHistory && isSearch)) &&
              <>
                {
                  (!propostasCompra.length && viewHistory) ?

                    <div className="row w-100" style={{ marginLeft: 65 }}>

                      <div className="col-12">
                        <p style={{ fontSize: 16, color: "#3C3C3B", textAlign: "left" }}>
                          <FontAwesomeIcon style={{ color: "#ECB800", marginRight: 15, fontSize: 19 }} icon={faInfoCircle} />
                          Não existem registros para este período.
                        </p>
                      </div>
                    </div>
                    :
                    <div style={{ padding: "0px 42px" }} className="w-100 row ">
                      <BootstrapTable
                        keyField="id"
                        data={propostasCompra}
                        bordered={false}
                        striped={true}
                        hover={true}
                        columns={columns}
                        rowEvents={rowEvents}
                        filter={filterFactory()}
                      />
                    </div>
                }

              </>

            }

          </>
        )
      }
    </>
  );
};

export default PurchaseProposal;
