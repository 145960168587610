import React, { useState, useEffect } from "react";
import Logo from "../assets/images/logoInvertida.svg";
import { Button } from "react-bootstrap";
import Carro from "../assets/images/yellow_car.png";
import { useDispatch } from "react-redux";
import { LoginThunk, selectUser } from "../features/user/userSlice";
import { TextField } from "@mui/material";
import { Formik } from "formik";
import * as Yup from "yup";
import { AtomInput } from "../utils/ui/input/AtomInput";
import { useAppSelector } from "../app/hooks";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ToastMessage from "./Toasts";
import { ChangePassword } from "../features/user/usuariosApi";

const Senha = () => {
  const dispatch = useDispatch();
  const user = useAppSelector(selectUser);
  const navigate = useNavigate();


  useEffect(() => {
    setForm(SCHEMA_FORM_RESET_PASSWORD_INITIAL_VALUES)
  }, [])

  const SCHEMA_FORM_RESET_PASSWORD = Yup.object().shape({
    password: Yup.string().required("Campo obrigatório"),
    newPassword: Yup.string().required("Campo obrigatório"),
    confirmedNewPassword: Yup.string().required("Campo obrigatório"),
  });
  const SCHEMA_FORM_RESET_PASSWORD_INITIAL_VALUES = {
    password: "",
    newPassword: "",
    confirmedNewPassword: ""
  };
  const [formResetPassword, setForm] = useState(SCHEMA_FORM_RESET_PASSWORD_INITIAL_VALUES);

  const atualizarSenha = async (values: any) => {
    if (values.newPassword != values.confirmedNewPassword) {
      renderToastError("As senhas devem ser iguais.");
      return;
    } else if (values.newPassword.length < 6) {
      renderToastError("Sua nova senha deve conter no mínimo 6 caracteres.");
      return;
    }

    const body = {
      id: user.id,
      email: user.email,
      password: values.password,
      confirmNewPassword: values.confirmedNewPassword,
      newPassword: values.newPassword
    }

    try {
      await ChangePassword(body);
      renderToastSucess("Senha alterada com sucesso.")
      navigate(`/dashboard`)
    } catch (error) {
      renderToastError("Senha atual incorreta.");
      return; 
    }
  }

  const renderToastError = (value: string) => {
    toast.error(
      <ToastMessage
        title="Erro"
        msg={value}
      />,
      {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      }
    );
  }

  const renderToastSucess = (value: string) => {
    toast.success(
      <ToastMessage
        title="Sucesso"
        msg={value}
      />,
      {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      }
    );
  }



  const renderTitleBloco = (value: string) => {
    return (
      <>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            marginLeft: "-42px",
            width: "100%",
            marginBottom: "13px"
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: 30,
              backgroundColor: "#bcbcbc",
              height: "32px",
            }}
          >
            <span
              style={{
                padding: "0 30px 0 42px",
                fontWeight: "bold",
                fontSize: 16,
                color: "#3C3C3B",
              }}
            >
              {value}
            </span>
          </div>
        </div>
      </>

    )
  }

  return (
    <div className='text-start' style={{ width: "100%", padding: "0px 42px", marginBottom: "30px" }}>


      <h1 style={{ fontSize: 22, fontWeight: "bold", marginTop: "20px" }}>
        Minha Senha
      </h1>

      {renderTitleBloco('Alterar senha')}

      <Formik
        initialValues={formResetPassword}
        validationSchema={SCHEMA_FORM_RESET_PASSWORD}
        onSubmit={async (values, { setSubmitting }) => {
          atualizarSenha(values);
        }}
      >
        {({
          errors,
          handleChange,
          submitForm,
          touched,
          isSubmitting,
          handleBlur,
          values,
        }) => (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <AtomInput
              icon={true}
              label="Senha atual *"
              placeholder="Senha atual *"
              type="password"
              width="50%"
              onChange={handleChange("password")}
              value={values.password}
              errors={errors.password}
              touched={touched.password}
              onBlur={handleBlur("password")}
            />
            <AtomInput
              icon={true}
              label="Nova senha *"
              placeholder="Nova senha *"
              type="password"
              width="50%"
              onChange={handleChange("newPassword")}
              value={values.newPassword}
              errors={errors.newPassword}
              touched={touched.newPassword}
              onBlur={handleBlur("newPassword")}
            />
            <AtomInput
              icon={true}
              label="Confirmar nova senha *"
              placeholder="Confirmar nova senha *"
              type="password"
              width="50%"
              onChange={handleChange("confirmedNewPassword")}
              value={values.confirmedNewPassword}
              errors={errors.confirmedNewPassword}
              touched={touched.confirmedNewPassword}
              onBlur={handleBlur("confirmedNewPassword")}
            />

            <Button
              disabled={isSubmitting}
              onClick={() => {
                submitForm();
              }}
              style={{
                borderRadius: 5,
                marginTop: 30,
                backgroundColor: "#3C3C3B",
                color: "white",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
                padding: "10px 0px",
                width: "180px",
                height: "35px",
                fontWeight: "bold",
                fontSize: "16px",
              }}
            >
              Salvar
            </Button>
          </div>
        )}
      </Formik>
    </div>
  );
};

export default Senha;
