import { Alert } from "react-bootstrap";
import ServiceApi from "../../api/index";
import { FaqNew } from "./userSlice";

/*  */
async function CreateFaq(payload: FaqNew) {
  const response = await ServiceApi.post(
    `new/faq`,
    { ...payload },
    {
      headers: {
        "content-type": "application/json",
      },
    }
  );
  return response.data;
}

async function LoadFaq() {
  const response = await ServiceApi.get(`all/faq`);
  return response.data;
}

async function DeleteFaq(id: string, token: string) {
  const response = await ServiceApi.delete(`faq/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
      "content-type": "application/json",
      accept: "*/*",
      Connection: "keep-alive",
    },
  });
  return response.data;
}

async function UpdateFaq(payload: any, id: string, token: string) {
  const response = await ServiceApi.put(
    `/update/faq/${id}`,
    { ...payload },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "content-type": "application/json",
        accept: "*/*",
        Connection: "keep-alive",
      },
    }
  );
  return response.data;
}

export { CreateFaq, LoadFaq, DeleteFaq, UpdateFaq };
